import React from "react";
import { useTranslation } from 'react-i18next';
import Tetris from "../../assets/images/microcourse/tetris.svg";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function ProgressList({ onClick, title, done, index, active }) {
  const defaultClass = "progress-item";
  let className = `${defaultClass}`;
  if (done) {
    className += " progress-completed";
  }
  if (active) {
    className += " progress-active";
  }
  return (
    <ui onClick={onClick} className={className}>
      <li>{title}</li>
    </ui>
  );
}

export default function MicrocourseProgress({
  goToSpecificStepCourse,
  lastStep,
  courseStep,
  courseId,
  lastCourse,
  final,
  courseName,
  courseIndex,
  isLoaded
}) {
  const { t } = useTranslation();

  function isDone(index) {
    if (index < lastStep && parseInt(courseId) === lastCourse) {
      return true;
    }
    if (courseId < lastCourse) {
      return true;
    }
    return false;
  }
  function isDoneFinal(index) {
    if (index < lastStep) {
      return true;
    }
    return false;
  }
  if (!final) {
    return (
      <div>
        <img src={Tetris} alt="tetris illustration" />

        {!isLoaded &&
          <>
            <Skeleton height={40} className='mt-4 mb-3' />
            <Skeleton height={20} className='mb-3' />
          </>
        }
        {
          isLoaded &&
          <>
            <h3>{courseName}</h3>
            <p>Microcourse {courseIndex}/10</p>
          </>
        }
        <div className="microcourse__progress-wrapper">
          <ProgressList
            title="Video"
            onClick={() => goToSpecificStepCourse(0)}
            done={isDone(0)}
            active={courseStep === 0}
            index={1}
          />
          <ProgressList
            title="Podcast"
            onClick={() => goToSpecificStepCourse(1)}
            done={isDone(1)}
            active={courseStep === 1}
            index={2}
          />
          <ProgressList
            title="Quiz"
            onClick={() => goToSpecificStepCourse(2)}
            done={isDone(2)}
            index={3}
            active={courseStep === 2}
          />
          <ProgressList
            title="Reflection"
            onClick={() => goToSpecificStepCourse(3)}
            done={isDone(3)}
            index={4}
            active={courseStep >= 3}
          />
        </div>
      </div>
    );
  }
  return (
    <div>
      <img src={Tetris} alt="tetris illustration" />
      <h3>{t('course.post.practicum')}</h3>
      <p>{t('course.post.postcourse')}</p>
      <div className="microcourse__progress-wrapper">
        <ProgressList
          title={t('course.post.review')}
          onClick={() => goToSpecificStepCourse(0)}
          done={isDoneFinal(0)}
          active={courseStep === 0}
          index={1}
        />
        <ProgressList
          title={t('course.post.ulp')}
          onClick={() => goToSpecificStepCourse(1)}
          done={isDoneFinal(1)}
          active={courseStep === 1}
          index={2}
        />
        <ProgressList
          title={t('course.post.uv')}
          onClick={() => goToSpecificStepCourse(2)}
          done={isDoneFinal(2)}
          index={3}
          active={courseStep === 2}
        />
        <ProgressList
          title={t('course.post.sr')}
          onClick={() => goToSpecificStepCourse(3)}
          done={isDoneFinal(3)}
          index={4}
          active={courseStep >= 3}
        />
      </div>
    </div>
  );
}
