import React, { useContext, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AuthContext } from '../../App';
import axios from 'axios';
import { Button, Form, Alert, InputGroup } from 'react-bootstrap';
import './Auth.scss';
import { useTranslation } from 'react-i18next';
import AuthSidebar from '../../components/AuthSidebar/AuthSidebar';
import FooterTetris from '../../assets/images/auth/footer-tetris.svg'

export default function Register(props) {
    const { t } = useTranslation()
    const { state } = useContext(AuthContext)
    const initialState = {
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        isSubmitting: false,
        errorMessage: null
    }
    const [data, setData] = useState(initialState);
    const handleInputChange = event => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }
    const handleFormSubmit = event => {
        event.preventDefault()
        setData({
            ...data,
            isSubmitting: true,
            errorMessage: null
        })
        const requestBody = {
            email: data.email,
            password: data.password,
            first_name: data.first_name,
            last_name: data.last_name
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}api/user`, requestBody)
            .then(function (response) {
                window.location.href = "/";
            })
            .catch(function (error) {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: "Email telah terdaftar atau data yang Anda masukkan salah."
                })
            })
    }
    if (state.isAuthenticated) {
        return <Redirect to="/dashboard" />
    }
    return (
        <div className='auth'>
            <div className='background-layer'>
                <AuthSidebar />
                <div className='auth-form'>
                    <div className='auth-form-title'>{t('slogan.title')}</div>
                    <div className='auth-form-subtitle'>
                        <span>{t('sign.up')}</span>
                    </div>
                    <Form onSubmit={handleFormSubmit} className='form-wrapper'>
                        <Form.Label>{t('email')}</Form.Label>
                        <InputGroup className='mb-3'>
                            <Form.Control name="email" value={data.email} onChange={handleInputChange} />
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.2857 2.14282H0.714286C0.319196 2.14282 0 2.46202 0 2.85711V17.1428C0 17.5379 0.319196 17.8571 0.714286 17.8571H19.2857C19.6808 17.8571 20 17.5379 20 17.1428V2.85711C20 2.46202 19.6808 2.14282 19.2857 2.14282ZM18.3929 4.61604V16.25H1.60714V4.61604L0.991071 4.13613L1.8683 3.00889L2.82366 3.7522H17.1786L18.1339 3.00889L19.0112 4.13613L18.3929 4.61604V4.61604ZM17.1786 3.74996L10 9.33032L2.82143 3.74996L1.86607 3.00666L0.988839 4.13389L1.60491 4.6138L9.22991 10.5424C9.44919 10.7127 9.71897 10.8052 9.99665 10.8052C10.2743 10.8052 10.5441 10.7127 10.7634 10.5424L18.3929 4.61604L19.0089 4.13613L18.1317 3.00889L17.1786 3.74996Z" fill="black" />
                            </svg>
                        </InputGroup>
                        <Form.Label>{t('first.name')}</Form.Label>
                        <InputGroup className='mb-3'>
                            <Form.Control name="first_name" value={data.first_name} onChange={handleInputChange} />
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.7343 15.616C17.3133 14.6186 16.7022 13.7126 15.9352 12.9486C15.1706 12.1823 14.2648 11.5713 13.2678 11.1494C13.2589 11.145 13.25 11.1428 13.241 11.1383C14.6317 10.1338 15.5357 8.49766 15.5357 6.65168C15.5357 3.59365 13.058 1.11597 9.99997 1.11597C6.94193 1.11597 4.46425 3.59365 4.46425 6.65168C4.46425 8.49766 5.36827 10.1338 6.7589 11.1405C6.74997 11.145 6.74104 11.1472 6.73211 11.1517C5.73211 11.5736 4.83479 12.1785 4.0647 12.9508C3.29843 13.7154 2.68748 14.6213 2.26559 15.6182C1.85113 16.5942 1.62761 17.6406 1.60711 18.7008C1.60652 18.7246 1.61069 18.7483 1.6194 18.7705C1.62811 18.7927 1.64117 18.8129 1.65781 18.83C1.67445 18.847 1.69434 18.8606 1.7163 18.8698C1.73826 18.8791 1.76185 18.8838 1.78568 18.8838H3.12497C3.22318 18.8838 3.30131 18.8057 3.30354 18.7097C3.34818 16.9865 4.04015 15.3727 5.26336 14.1494C6.52899 12.8838 8.20979 12.1874 9.99997 12.1874C11.7901 12.1874 13.471 12.8838 14.7366 14.1494C15.9598 15.3727 16.6518 16.9865 16.6964 18.7097C16.6986 18.8079 16.7768 18.8838 16.875 18.8838H18.2143C18.2381 18.8838 18.2617 18.8791 18.2836 18.8698C18.3056 18.8606 18.3255 18.847 18.3421 18.83C18.3588 18.8129 18.3718 18.7927 18.3805 18.7705C18.3892 18.7483 18.3934 18.7246 18.3928 18.7008C18.3705 17.6338 18.1495 16.5959 17.7343 15.616ZM9.99997 10.491C8.97542 10.491 8.01113 10.0914 7.28568 9.36597C6.56024 8.64052 6.16068 7.67623 6.16068 6.65168C6.16068 5.62713 6.56024 4.66284 7.28568 3.9374C8.01113 3.21195 8.97542 2.8124 9.99997 2.8124C11.0245 2.8124 11.9888 3.21195 12.7143 3.9374C13.4397 4.66284 13.8393 5.62713 13.8393 6.65168C13.8393 7.67623 13.4397 8.64052 12.7143 9.36597C11.9888 10.0914 11.0245 10.491 9.99997 10.491Z" fill="black" />
                            </svg>
                        </InputGroup>
                        <Form.Label>{t('last.name')}</Form.Label>
                        <InputGroup className='mb-3'>
                            <Form.Control name="last_name" value={data.last_name} onChange={handleInputChange} />
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.7343 15.616C17.3133 14.6186 16.7022 13.7126 15.9352 12.9486C15.1706 12.1823 14.2648 11.5713 13.2678 11.1494C13.2589 11.145 13.25 11.1428 13.241 11.1383C14.6317 10.1338 15.5357 8.49766 15.5357 6.65168C15.5357 3.59365 13.058 1.11597 9.99997 1.11597C6.94193 1.11597 4.46425 3.59365 4.46425 6.65168C4.46425 8.49766 5.36827 10.1338 6.7589 11.1405C6.74997 11.145 6.74104 11.1472 6.73211 11.1517C5.73211 11.5736 4.83479 12.1785 4.0647 12.9508C3.29843 13.7154 2.68748 14.6213 2.26559 15.6182C1.85113 16.5942 1.62761 17.6406 1.60711 18.7008C1.60652 18.7246 1.61069 18.7483 1.6194 18.7705C1.62811 18.7927 1.64117 18.8129 1.65781 18.83C1.67445 18.847 1.69434 18.8606 1.7163 18.8698C1.73826 18.8791 1.76185 18.8838 1.78568 18.8838H3.12497C3.22318 18.8838 3.30131 18.8057 3.30354 18.7097C3.34818 16.9865 4.04015 15.3727 5.26336 14.1494C6.52899 12.8838 8.20979 12.1874 9.99997 12.1874C11.7901 12.1874 13.471 12.8838 14.7366 14.1494C15.9598 15.3727 16.6518 16.9865 16.6964 18.7097C16.6986 18.8079 16.7768 18.8838 16.875 18.8838H18.2143C18.2381 18.8838 18.2617 18.8791 18.2836 18.8698C18.3056 18.8606 18.3255 18.847 18.3421 18.83C18.3588 18.8129 18.3718 18.7927 18.3805 18.7705C18.3892 18.7483 18.3934 18.7246 18.3928 18.7008C18.3705 17.6338 18.1495 16.5959 17.7343 15.616ZM9.99997 10.491C8.97542 10.491 8.01113 10.0914 7.28568 9.36597C6.56024 8.64052 6.16068 7.67623 6.16068 6.65168C6.16068 5.62713 6.56024 4.66284 7.28568 3.9374C8.01113 3.21195 8.97542 2.8124 9.99997 2.8124C11.0245 2.8124 11.9888 3.21195 12.7143 3.9374C13.4397 4.66284 13.8393 5.62713 13.8393 6.65168C13.8393 7.67623 13.4397 8.64052 12.7143 9.36597C11.9888 10.0914 11.0245 10.491 9.99997 10.491Z" fill="black" />
                            </svg>
                        </InputGroup>
                        <Form.Label>{t('password')}</Form.Label>
                        <InputGroup className='mb-3'>
                            <Form.Control name="password" type="password" value={data.password} onChange={handleInputChange} />
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.1428 8.92868H15.625V3.92868C15.625 2.35055 14.3459 1.07153 12.7678 1.07153H7.23211C5.65398 1.07153 4.37497 2.35055 4.37497 3.92868V8.92868H2.85711C2.46202 8.92868 2.14282 9.24787 2.14282 9.64296V18.2144C2.14282 18.6095 2.46202 18.9287 2.85711 18.9287H17.1428C17.5379 18.9287 17.8571 18.6095 17.8571 18.2144V9.64296C17.8571 9.24787 17.5379 8.92868 17.1428 8.92868ZM5.98211 3.92868C5.98211 3.23894 6.54238 2.67868 7.23211 2.67868H12.7678C13.4576 2.67868 14.0178 3.23894 14.0178 3.92868V8.92868H5.98211V3.92868ZM16.25 17.3215H3.74997V10.5358H16.25V17.3215ZM9.37497 14.2189V15.4019C9.37497 15.5001 9.45532 15.5805 9.55354 15.5805H10.4464C10.5446 15.5805 10.625 15.5001 10.625 15.4019V14.2189C10.8092 14.0866 10.9468 13.8992 11.0178 13.6838C11.0888 13.4684 11.0896 13.236 11.0201 13.02C10.9506 12.8041 10.8144 12.6158 10.6311 12.4822C10.4478 12.3487 10.2268 12.2767 9.99997 12.2767C9.77314 12.2767 9.55216 12.3487 9.36884 12.4822C9.18552 12.6158 9.04932 12.8041 8.97983 13.02C8.91035 13.236 8.91117 13.4684 8.98217 13.6838C9.05318 13.8992 9.19071 14.0866 9.37497 14.2189Z" fill="black" />
                            </svg>
                        </InputGroup>
                        {data.errorMessage && (
                            <Alert variant="danger">
                                {data.errorMessage}
                            </Alert>
                        )}
                        <Button className='w-100 auth-button' variant="danger" type="submit" disabled={data.isSubmitting}>
                            {data.isSubmitting ? (
                                    t('loading')
                                ) : (
                                    t('sign.up')
                            )}
                        </Button>
                        <div className='cta-label mt-4'>
                            <span>{t('already.have.an.account')}</span>
                            <Link to='/'>{t('sign.in')}</Link>
                        </div>
                    </Form>
                    <img className='auth-footer-tetris' src={FooterTetris} alt='tetris' />
                </div>
            </div>
        </div>
    )
}