import axios from "axios";

import { useEffect, useContext, useState, useReducer, useRef } from "react";
import { Row, Col, Button, Form, Alert } from "react-bootstrap";
import { useHistory, Link, Redirect } from "react-router-dom";

import { AuthContext } from "../../App";
import { useTranslation } from 'react-i18next';

import BackButton from "../../components/Button/BackButton";
import JourneyNavigationBar from "../../components/JourneyNavigationBar";
import MicrocourseProgress from "./MicrocourseProgress";
import SinglePagesPDFViewer from "../../components/Pdf/single-page";
import { StepList, Step } from "../../components/Stepper/Index";
import VideoPage from "./VideoPage";
import UploadForm from "./UploadForm";

import ArrowLeft from "../../assets/images/arrow-left.svg";
import ArrowRight from "../../assets/images/arrow-right.svg";
import Nuklir from "../../assets/pdf/mobile.pdf";
import TetrisPiece1 from "../../assets/images/microcourse/tetris-piece-1.svg";
import TetrisPiece2 from "../../assets/images/microcourse/tetris-piece-2.svg";
import TetrisPiece3 from "../../assets/images/microcourse/tetris-piece-3.svg";
import TetrisPiece4 from "../../assets/images/microcourse/tetris-piece-4.svg";
import TetrisPiece5 from "../../assets/images/microcourse/tetris-piece-5.svg";
import TetrisPiece6 from "../../assets/images/microcourse/tetris-piece-6.svg";
import Review1 from "../../assets/images/microcourse/review-1.svg";
import Review2 from "../../assets/images/microcourse/review-2.svg";
import Review3 from "../../assets/images/microcourse/review-3.svg";
import Review4 from "../../assets/images/microcourse/review-4.svg";
import Review5 from "../../assets/images/microcourse/review-5.svg";

import "./Microcourse.scss";

const tetrisStyle = {
  zIndex: 0,
};

function FinalMicrocourse(props) {
  const history = useHistory();
  const [postCourseID, setPostCourseID] = useState(0);
  const [moduleID, setModuleID] = useState(0)
  const [activeCourseStep, setActivecourseStep] = useState(-1);
  const [rules, setRules] = useState("");
  const [reviews, setReviews] = useState([]);
  const [reviewAnswers, setReviewAnswers] = useState([]);
  const [reviewSubmited, setReviewSubmited] = useState(false);
  const [currentReviewStep, setCurrentReviewStep] = useState(0);
  const [selfReviews, setSelfReviews] = useState([]);
  const [selfReviewAnswers, setSelfReviewAnswers] = useState([]);
  const [selfReviewSubmited, setSelfReviewSubmited] = useState(false);
  const [selfReviewError, setSelfReviewError] = useState(false);
  const [journeyName, setJourneyName] = useState("");
  const [courseExpired, setCourseExpired] = useState(false);
  const [currentUserProgres, setCurrentUserProgress] = useState({});
  const { state, dispatch } = useContext(AuthContext);
  const alertRef = useRef(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { t } = useTranslation();

  const journeyID = props.match.params.journeyID;

  const videoURL = "https://www.youtube.com/watch?v=U52IJSyHa24";

  const fetchData = () => {
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + state.token,
      },
    };

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/v2/master/id/journey/${journeyID}/post_course`,
        config
      )
      .then((res) => {
        setReviews(res.data.review);
        setSelfReviews(res.data.self_review);
        setPostCourseID(res.data.post_course_id);
        setRules(res.data.desc);

        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}api/id/post_course/${res.data.post_course_id}/user_post_course_answer/self_review`,
            config
          )
          .then((resAnswer) => {
            setSelfReviewAnswersFromAPI(res.data.self_review, resAnswer.data);
          })
          .catch((e) => {
            console.log(e);
          });

        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}api/id/post_course/${res.data.post_course_id}/user_post_course_answer/review`,
            config
          )
          .then((resAnswer) => {
            setReviewAnswersFromAPI(res.data.review, resAnswer.data);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/v2/master/id/journey/${journeyID}`,
        config
      )
      .then((res) => {
        setJourneyName(res.data.journey_name);
        setModuleID(res.data.module_id)
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/master/user-progress`, config)
      .then((res) => {
        getCurrentUserProgressFromAPI(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function setSelfReviewAnswersFromAPI(question, answers) {
    if (answers.length > 0) {
      setSelfReviewSubmited(true);
    }
    answers.forEach((a) => {
      let answer = selfReviewAnswers;
      const index = question
        .map(function (q) {
          return q.question_id;
        })
        .indexOf(a.question_id);
      answer[index] = a.answer;
      setSelfReviewAnswers(answer);
    });
  }

  function setReviewAnswersFromAPI(question, answers) {
    if (answers.length > 0) {
      setReviewSubmited(true);
    }
    answers.forEach((a) => {
      let answer = reviewAnswers;
      const index = question
        .map(function (q) {
          return q.question_id;
        })
        .indexOf(a.question_id);
      answer[index] = a.answer;
      setReviewAnswers(answer);
    });
  }

  function getCurrentUserProgress() {
    const userProgress = state.userProgress.find(
      (o) => o.journey_id === parseInt(journeyID)
    );
    if (userProgress) {
      setCurrentUserProgress(userProgress);
    }
  }

  function getCurrentUserProgressFromAPI(newUserProgress) {
    dispatch({
      type: "USER_PROGRESS",
      payload: newUserProgress,
    });
    const userProgress = newUserProgress.find(
      (o) => o.journey_id === parseInt(journeyID)
    );
    if (userProgress) {
      setCurrentUserProgress(userProgress);
    }
  }

  const timeout = () => {
    setTimeout(() => {
      console.log("Token telah berakhir");
      dispatch({
        type: "LOGOUT",
      });
    }, state.tokenExpires);
  };

  useEffect(() => {
    startPostCourse();
    fetchData();
    getCurrentUserProgress();
    // eslint-disable-next-line
    timeout();
    // eslint-disable-next-line
  }, []);

  function ReviewOpening() {
    return (
      <div className="quiz-box quiz-box-gate">
        <h1 style={{ marginBottom: 28 }}>{t('course.post.sr.header')}</h1>
        <Button className="blue-button" onClick={goNextReviewStep}>
          {t('course.post.sr.start')}
        </Button>
      </div>
    );
  }

  function ReviewClosing() {
    return (
      <div className="">
        <h1 style={{ marginBottom: 28, marginTop: "3.5em" }}>
          {t('course.post.sr.finish')}
        </h1>
        {selfReviewError && <p style={{color: "red", fontSize: "2em"}}>Please fill all the answer</p> }
        {!selfReviewSubmited && (
          <Button
            onClick={onSubmitSelfReview}
            style={{ margin: "auto" }}
            className="blue-button"
          >
            {t('submit')}
          </Button>
        )}
      </div>
    );
  }

  function EndCourse() {
    return (
      <div className="">
        <h1 style={{ marginBottom: 28, marginTop: "3.5em" }}>
          {t('course.post.finish.1')}
        </h1>
        <p>
          {t('course.post.finish.2', {
            journeyName: journeyName
          })}
        </p>
        <Link to={`/module/${moduleID}/journey`}>
        <Button style={{ margin: "auto" }} className="blue-button">
          {t('course.post.finish.return')}
        </Button>
        </Link>
      </div>
    );
  }

  function ReviewRateNumber({ index, value, src, alt, allAnswer, disabled }) {
    const activeRating = { border: "13px solid #e30377", borderRadius: "50%" };

    let rating = { marginRight: "1em", zIndex: "1" };
    if (allAnswer[index] != null && parseInt(allAnswer[index]) === value) {
      rating = Object.assign({}, rating, activeRating);
    }
    if (disabled) {
      return <img style={rating} src={src} alt={alt} />;
    }
    return (
      <img
        style={rating}
        src={src}
        alt={alt}
        onClick={() => onAddAnswerSelfReviewRate(index, value)}
      />
    );
  }

  function ReviewAnswer({ type, index, allAnswer, isSubmited }) {
    if (type === "essay") {
      return (
        <>
          <Form className="w-100 reflection-form">
            <Form.Group controlId="ControlTextarea1">
              <Form.Control
                as="textarea"
                rows={10}
                defaultValue={allAnswer[index] || ""}
                onChange={(e) => onAddAnswerSelfReview(index, e.target.value)}
                disabled={isSubmited}
              />
            </Form.Group>
          </Form>
        </>
      );
    }
    return (
      <div className="float-left">
        <ReviewRateNumber
          src={Review1}
          alt="rate-1"
          index={index}
          value={1}
          allAnswer={allAnswer}
          disabled={isSubmited}
        />
        <ReviewRateNumber
          src={Review2}
          alt="rate-2"
          index={index}
          value={2}
          allAnswer={allAnswer}
          disabled={isSubmited}
        />
        <ReviewRateNumber
          src={Review3}
          alt="rate-3"
          index={index}
          value={3}
          allAnswer={allAnswer}
          disabled={isSubmited}
        />
        <ReviewRateNumber
          src={Review4}
          alt="rate-4"
          index={index}
          value={4}
          allAnswer={allAnswer}
          disabled={isSubmited}
        />
        <ReviewRateNumber
          src={Review5}
          alt="rate-5"
          index={index}
          value={5}
          allAnswer={allAnswer}
          disabled={isSubmited}
        />
      </div>
    );
  }

  function onChangeReview(index, answer) {
    let answers = reviewAnswers;
    answers[index] = answer;
    setReviewAnswers(answers);
  }

  function onAddAnswerSelfReviewRate(index, answer) {
    onAddAnswerSelfReview(index, answer);
    forceUpdate();
  }

  function onAddAnswerSelfReview(index, answer) {
    const answers = selfReviewAnswers;
    answers[index] = answer;
    setSelfReviewAnswers(answers);
  }

  function onSubmitSelfReview() {
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + state.token,
      },
    };
    if(selfReviews.length !== selfReviewAnswers.length){
      setSelfReviewError(true)
      return null
    }
    let error = false
    for(let i=0; i < selfReviewAnswers.length; i++ ){
      if(!selfReviewAnswers[i]){
        error = true
        break;
      }
    }
    if(error){
      setSelfReviewError(true)
      return null
    }

    let data = {};
    let selfReviewAnswersPayload = [];
    selfReviewAnswersPayload = selfReviewAnswers.map((answer, index) => {
      let payload = {};
      payload["answer"] = answer;
      payload["question_id"] = selfReviews[index].question_id;
      return payload;
    });

    data["post_course_id"] = postCourseID;
    data["flag"] = "self_review";
    data["user_post_course_answers"] = selfReviewAnswersPayload;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api/post_course/submit_answer`,
        data,
        config
      )
      .then(function () {
        setSelfReviewSubmited(true);
        sendUserProgress();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function onSubmitUpload() {
    sendUserProgress();
  }

  function sendUserProgress() {
    const payload = {
      post_course_last_step: activeCourseStep + 1,
    };
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + state.token,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api/user_progress/${journeyID}/next_post_course_step`,
        payload,
        config
      )
      .then(function (res) {
        if (res.status === 200) {
          dispatch({
            type: "USER_PROGRESS",
            payload: res.data.user_progress,
          });
          getCurrentUserProgressFromAPI(res.data.user_progress);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function onSubmitReview() {
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + state.token,
      },
    };
    let error = false
    if(reviews.length !== reviewAnswers.length){
      return null
    }
    for(let i=0; i < reviewAnswers.length; i++ ){
      if(!reviewAnswers[i]){
        error = true
        break;
      }
    }
    if(error){
      return null
    }
    let data = {};
    let reviewAnswersPayload = [];
    reviewAnswersPayload = reviewAnswers.map((answer, index) => {
      let payload = {};
      payload["answer"] = answer;
      payload["question_id"] = reviews[index].question_id;
      return payload;
    });
    data["post_course_id"] = postCourseID;
    data["flag"] = "review";
    data["user_post_course_answers"] = reviewAnswersPayload;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api/post_course/submit_answer`,
        data,
        config
      )
      .then(function () {
        setReviewSubmited(true);
        sendUserProgress();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function goToSpecificStepCourse(index) {
    if (index <= currentUserProgres.post_course_last_step) {
      setActivecourseStep(index);
    }
  }

  function goNextCourseStep(e) {
    const step = activeCourseStep + 1;
    setActivecourseStep(step);
  }
  function goPreviousCourseStep(e) {
    const step = activeCourseStep - 1;
    setActivecourseStep(step);
  }

  function goNextReviewStep() {
    setCurrentReviewStep(currentReviewStep + 1);
  }
  function goPreviousReviewStep() {
    setSelfReviewError(false)
    setCurrentReviewStep(currentReviewStep - 1);
  }

  function startPostCourse() {
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + state.token,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/user_progress/${journeyID}/start_post_course`,
        config
      )
      .then(function (res) {
        if (res.status === 200) {
          dispatch({
            type: "USER_PROGRESS",
            payload: res.data.user_progress,
          });
          getCurrentUserProgressFromAPI(res.data.user_progress);
          setCourseExpired(res.data.reset_post_course);
          if(res.data.reset_post_course){
            scrollToAlert()
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function scrollToAlert() {
    alertRef.current.scrollIntoView();
  }

  if (!state.isAuthenticated) {
    return <Redirect to="/" />
  }

  return (
    <>
      <JourneyNavigationBar />
      <div className="content-wrapper microcourse">
        <div className="microcourse__content-wrapper">
          <Row>
            {courseExpired && (
              <Alert ref={alertRef} variant="danger">
                Your progress has been reseted because you exceeded time limit
              </Alert>
            )}
            <Col md="12"></Col>
            <Col md="12" style={{ marginBottom: 50, marginTop: 50 }}>
              <BackButton onClick={() => history.goBack()} />
            </Col>
            <Col md="12" lg={4}>
              <MicrocourseProgress
                lastStep={currentUserProgres.post_course_last_step || 0}
                courseStep={activeCourseStep}
                courseId={11}
                lastCourse={11}
                goToSpecificStepCourse={goToSpecificStepCourse}
                final
              />
            </Col>
            <Col md="12" lg={8}>
              <div className="microcourse-content-wrapper">
                <div className="content-title">
                  <h1>{t('course.welcome')}</h1>
                  <h1>{t('course.post.post-course')}</h1>
                </div>
                {activeCourseStep === 3 && (
                  <div className="microcourse__quiz">
                    <>
                      {/* <ProgressBar
                        className="quiz-progressbar"
                        now={(1 / (4 + 1)) * 100}
                      /> */}
                      <div className="reflection">
                        <img
                          className="tetris-decoration-1"
                          src={TetrisPiece1}
                          alt="tetris piece"
                          style={tetrisStyle}
                        />
                        <img
                          className="tetris-decoration-2"
                          src={TetrisPiece2}
                          alt="tetris piece"
                          style={tetrisStyle}
                        />
                        <img
                          className="tetris-decoration-3"
                          src={TetrisPiece3}
                          alt="tetris piece"
                          style={tetrisStyle}
                        />
                        <img
                          className="tetris-decoration-4"
                          src={TetrisPiece4}
                          alt="tetris piece"
                          style={tetrisStyle}
                        />
                        <img
                          className="tetris-decoration-5"
                          src={TetrisPiece5}
                          alt="tetris piece"
                          style={tetrisStyle}
                        />
                        <img
                          className="tetris-decoration-6"
                          src={TetrisPiece6}
                          alt="tetris piece"
                          style={tetrisStyle}
                        />
                        {/* {activeQuizStep > 0 && activeQuizStep <= totalQuiz ? (
                        <div>
                          Question {activeQuizStep}/{totalQuiz}{" "}
                          {activeQuizStep <= totalQuiz}
                        </div>
                      ) : null} */}
                        {reviews.length > 0 && (
                          <StepList
                            currentStep={currentReviewStep}
                            goNextStep={goNextReviewStep}
                            goPreviousStep={goPreviousReviewStep}
                          >
                            <Step
                              key="reviewOpening"
                              displayNext={false}
                              component={ReviewOpening}
                            />
                            {selfReviews.map((sr, index) => (
                              <Step key={sr.question_id}>
                                <h1>{sr.question}</h1>
                                <ReviewAnswer
                                  type={sr.type}
                                  index={index}
                                  allAnswer={selfReviewAnswers}
                                  isSubmited={selfReviewSubmited}
                                />
                              </Step>
                            ))}
                            <Step
                              displaySubmit={false}
                              displayNext={selfReviewSubmited}
                              component={ReviewClosing}
                            />
                            <Step
                              displaySubmit={false}
                              displayPrevious={false}
                              component={EndCourse}
                            />
                          </StepList>
                        )}
                      </div>
                    </>
                  </div>
                )}

                {activeCourseStep === -1 && (
                  <Col md={12} className="congratulations-card">
                    <h1>{t('course.post.finish.3')}{journeyName}</h1>
                  </Col>
                )}
                {activeCourseStep === 0 || activeCourseStep === 2 ? (
                  <VideoPage videoURL={videoURL} />
                ) : null}
                {activeCourseStep === 1 ? (
                  <SinglePagesPDFViewer pdf={Nuklir} />
                ) : null}
                {activeCourseStep === -1 && (
                  <p className="mt-3">
                    <i>{t('course.post.whatnext')}</i>
                  </p>
                )}
                {activeCourseStep === 0 && (
                  <p className="mt-3">
                    <i>{t('course.post.review.header')}:</i>
                  </p>
                )}
                {activeCourseStep === 1 && (
                  <p className="mt-3">
                    <i>{t('course.post.ulp.header')}:</i>
                  </p>
                )}
                {activeCourseStep === 2 && (
                  <p className="mt-3">
                    <i>{t('course.post.uv.header')}:</i>
                  </p>
                )}
                <Col md="12">
                  <div className="divider"></div>
                </Col>
                {activeCourseStep === 1 || activeCourseStep === 2 ? (
                  <div>
                    {activeCourseStep === 1 && (
                      <div>
                        <p>{t('course.post.ulp.rule.1')}</p>
                        <p>{t('course.post.ulp.rule.2')}</p>
                        <ol>
                          <li>{t('course.post.ulp.rule.3')}</li>
                          <li>{t('course.post.ulp.rule.4')}</li>
                        </ol>
                        <UploadForm
                          token={state.token}
                          journeyID={props.match.params.journeyID}
                          bodyName="lesson_plan"
                          subtitle={t('course.post.ulp.form.subtitle')}
                          accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          uploadURL={`${process.env.REACT_APP_BASE_URL}api/post_course/upload_lesson_plan`}
                          onSubmit={onSubmitUpload}
                          submited={
                            currentUserProgres.post_course_last_step > 1
                          }
                        />
                      </div>
                    )}
                    {activeCourseStep === 2 && (
                      <div>
                        <p>{t('course.post.uv.rule.1')}</p>
                        <p>{t('course.post.ulp.rule.2')}</p>
                        <ol>
                          <li>
                            {t('course.post.uv.rule.3')}
                            <ol type="a">
                              <li>{t('course.post.uv.rule.4')}</li>
                              <li>{t('course.post.uv.rule.5')}</li>
                              <li>{t('course.post.uv.rule.6')}</li>
                              <li>{t('course.post.uv.rule.7')}</li>
                            </ol>
                          </li>
                          <li>{t('course.post.uv.rule.8')}</li>
                        </ol>
                        <UploadForm
                          token={state.token}
                          journeyID={props.match.params.journeyID}
                          subtitle={t('course.post.uv.form.subtitle')}
                          accept="video/mp4"
                          bodyName="video"
                          uploadURL={`${process.env.REACT_APP_BASE_URL}api/post_course/upload_video`}
                          onSubmit={onSubmitUpload}
                          submited={
                            currentUserProgres.post_course_last_step > 2
                          }
                        />
                      </div>
                    )}
                  </div>
                ) : null}
                {activeCourseStep === 0 ? (
                  <Col md="12">
                    <div className="content-detail-wrapper">
                      <div id="pre-test">
                        <Form>
                          {reviews.map((review, index) => (
                            <Form.Group controlId="ControlTextarea1">
                              <Form.Label>
                                {index + 1}. {review.question}
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={10}
                                defaultValue={reviewAnswers[index]}
                                disabled={reviewSubmited}
                                onChange={(e) =>
                                  onChangeReview(index, e.target.value)
                                }
                              />
                            </Form.Group>
                          ))}
                        </Form>
                        <Button
                          onClick={onSubmitReview}
                          className="black-button"
                          disabled={reviewSubmited}
                        >
                          {t('submit')}
                        </Button>
                      </div>
                    </div>
                  </Col>
                ) : null}
                {activeCourseStep === -1 ? (
                  <Col md="12">
                    <div className="content-detail-wrapper">
                      <div id="pre-test">
                        <div dangerouslySetInnerHTML={{ __html: rules }} />
                      </div>
                    </div>
                  </Col>
                ) : null}
                <div className="button-group">
                  {activeCourseStep >= 0 && (
                    <Button
                      className="white-button"
                      style={{ marginRight: 23, marginTop: "1em" }}
                      onClick={goPreviousCourseStep}
                    >
                      <img src={ArrowLeft} alt="arrow" />
                      {t('back')}
                    </Button>
                  )}
                  {activeCourseStep < 3 && (
                    <Button
                      className="black-button"
                      style={{ marginRight: 23, marginTop: "1em" }}
                      onClick={goNextCourseStep}
                      disabled={
                        activeCourseStep >=
                        currentUserProgres.post_course_last_step
                      }
                    >
                      {t('next')} <img src={ArrowRight} alt="arrow" />
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row></Row>
        </div>
      </div>
    </>
  );
}
export default FinalMicrocourse;
