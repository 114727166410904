import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../App';

function useFetch(url) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const { state } = useContext(AuthContext);

  useEffect(() => {
    setLoading('loading...')
    setData(null);
    setError(null);
    const source = axios.CancelToken.source();
    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.token
        }
    }
    axios.get(url, config)
      .then(res => {
        setLoading(false);
        res.data && setData(res.data);
        res.content && setData(res.content);
      })
      .catch(err => {
        setLoading(false)
        setError('An error occurred. Awkward..')
      })
    return () => {
      source.cancel();
    }
  }, [url, state.token])

  return { data, loading, error }
}

export default useFetch;