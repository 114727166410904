import React from "react";

class StepList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: this.props.currentStep || 0,
      totalSteps: React.Children.count(this.props.children) -1,
    };

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.currentStep !== this.state.currentStep) {
      this.setState({ currentStep: nextProps.currentStep });
    }
  }

  goToPreviousStep = () => {
    if(this.props.goPreviousStep == null){
      this.setState({ currentStep: this.state.currentStep - 1 });
    }else {
      this.props.goPreviousStep()
    }
  };

  goToNextStep = () => {
    if(this.props.goNextStep == null){
      this.setState({ currentStep: this.state.currentStep + 1 });
    }else {
      this.props.goNextStep()
    }
  };

  render() {
    const children = React.Children.map(this.props.children, (child, index) => {
      const { currentStep, totalSteps } = this.state;
      if (this.props.displayPrevious === null){
        this.props.displayPrevious = true
      }
      if (this.props.displayNext === null){
        this.props.displayNext = true
      }
      return React.cloneElement(child, {
        isActive: index === currentStep,
        displayPrevious: currentStep > 0 && this.props.displayPrevious,
        displayNext: currentStep < totalSteps && this.props.displayNext ,
        displaySubmit: currentStep === totalSteps,
        goToPreviousStep: () => this.goToPreviousStep(),
        goToNextStep: () => this.goToNextStep(),
        ...child.props
      });
    });

    return children;
  }
}

export { StepList };
