import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../App';
import './Auth.scss';
import AuthSidebar from '../../components/AuthSidebar/AuthSidebar';
import FooterTetris from '../../assets/images/auth/footer-tetris.svg';
import EmailSent from '../../assets/images/auth/email-sent.gif';

export default function ForgotPasswordSent() {
    const { state } = useContext(AuthContext);

    if (state.isAuthenticated) {
        return <Redirect to="/dashboard" />
    }

    return (
        <div className='auth'>
            <div className='background-layer'>
                <AuthSidebar />

                <div className='auth-form email-sent-wrapper pt-5'>
                    <img src={EmailSent} className='email-sent' alt='email sent' />
                    <div className='auth-form-title'>Email has been sent !</div>
                    <p className='mt-2'>Check your inbox, we sent an email with a link to return to your account.</p>
                    <img className='auth-footer-tetris' src={FooterTetris} alt='tetris' />
                </div>
            </div>
        </div>
    )
}
