import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { AuthContext } from '../../App';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom'
import { Row, Col, Form, Button, Nav, Modal, Alert } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import JourneyNavigationBar from '../../components/JourneyNavigationBar';
import ReactPlayer from 'react-player';
import ArrowRight from '../../assets/images/arrow-right.svg';
import Tetris from "../../assets/images/microcourse/tetris.svg";
import './Microcourse.scss';

export default function IndexPrecourse(props) {
    const [precourse, setPrecourse] = useState([]);
    const [journey, setJourney] = useState({});
    const { state, dispatch } = useContext(AuthContext);
    const [precourseAnswer, setPrecourseAnswer] = useState([]);
    const [preCourseError, setPreCourseError] = useState(false);
    const [precourseId, setPrecourseId] = useState();
    const [show, setShow] = useState(false);
    const { t } = useTranslation();

    function handleClose() {
        setShow(false);
        props.history.push(`/microcourse/`+ journey?.courses[0].course_id)
    }

    function handleChangePrecourse(index, value) {
        let answers = precourseAnswer;
        answers[index] = value;
        setPrecourseAnswer(answers);
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }
        let payload = {}
        const pcAnswer = precourseAnswer;
        let error = false;
        if (pcAnswer.length !== precourse.pre_test.length) {
            setPreCourseError(true)
            return null
        }
        for (let i = 0; i < pcAnswer.length; i++) {
            if (!pcAnswer[i]) {
                error = true
                break;
            }
        }
        if (error) {
            setPreCourseError(true)
            return null
        }
        let pcPayload = pcAnswer.map((answer, index) => {
            let tempAnswer = {}
            tempAnswer["pre_test_id"] = precourse.pre_test[index].pre_test_id
            tempAnswer["answer"] = answer;
            return tempAnswer;
        })
        payload["pre_course_id"] = precourseId;
        payload["pre_course_answers"] = pcPayload;
        axios.post(`${process.env.REACT_APP_BASE_URL}api/submit_pre_test`, payload, config)
            .then(function (res) {
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error.response.data);
            });
        //show modal when survey finish
        setShow(true);
    }
    const fetchData = () => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}api/v2/master/id/journey/` + props.match.params.id + `/pre_course`, config)
            .then(res => {
                setPrecourse(res.data);
                setPrecourseId(res.data.pre_course_id);
            })
            .catch(e => {
                console.log(e)
            })

        axios.get(`${process.env.REACT_APP_BASE_URL}api/v2/master/id/journey/` + props.match.params.id + ``, config)
            .then(res => {
                setJourney(res.data);
            })
            .catch(e => {
                console.log(e)
            })
    }

    const timeout = () => {
        setTimeout(() => {
            console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
        timeout()
        // eslint-disable-next-line
    }, [])

    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    return (
        <>
            <JourneyNavigationBar />
            <div className='content-wrapper microcourse'>
                <Row>
                    <Col md='3'>
                        <div className='p-4'>
                            <img src={Tetris} alt="tetris illustration" />
                            <h3>{journey.journey_name}</h3>
                            <p>{t('course.pre.precourse')}</p>
                        </div>
                    </Col>
                    <Col md='9'>
                        <div className='player-wrapper'>
                            <ReactPlayer className='react-player' width='100%' height='100%' url={precourse.video_url} controls={true} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md='12'>
                        <div className='divider'></div>
                    </Col>
                    <Col md='12'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="about">
                            <Row>
                                <Col sm={3}>
                                    <Nav variant="pills" className="flex-column left-nav-course">
                                        <Nav.Item>
                                            <Nav.Link className='menu-item' eventKey="about">{t('course.pre.about')}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link className='menu-item' eventKey="pre-test">{t('course.pre.pretest')}</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={9}>
                                    <Tab.Content className='content-detail-wrapper precourse'>
                                        <Tab.Pane eventKey="about">
                                            <div dangerouslySetInnerHTML={{ __html: precourse.desc }} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="pre-test">
                                            <h3>{t('course.pre.pretest')}</h3>
                                            {preCourseError && <Alert variant='danger'>Semua jawaban harus terisi</Alert>}
                                            <Form onSubmit={handleFormSubmit}>
                                                {precourse.pre_test && precourse.pre_test.map((pre, index) => (
                                                    <div id='pre-test' key={index}>
                                                        <Form.Group>
                                                            <Form.Label>{pre.question}</Form.Label>
                                                            {(() => {
                                                                if (pre.type === 'essay') {
                                                                    return (
                                                                        <Form.Control as="textarea" rows={10} onChange={(e) => handleChangePrecourse(index, e.target.value)} />
                                                                    )
                                                                } else if (pre.type === 'level') {
                                                                    return (
                                                                        <div className='d-flex flex-row mt-3 justify-content-between justify-content-md-start w-100'>
                                                                            <h5 className='pr-3'>Tidak Percaya Diri</h5>
                                                                            <div className='d-flex flex-row'>
                                                                                <Form.Check
                                                                                    type="radio"
                                                                                    id={`default-radio-1`}
                                                                                    name={index}
                                                                                    className='mx-1 mx-md-3 mb-5'
                                                                                    value='1'
                                                                                    onChange={(e) => handleChangePrecourse(index, e.target.value)}
                                                                                />
                                                                                <Form.Check
                                                                                    type="radio"
                                                                                    id={`default-radio-2`}
                                                                                    name={index}
                                                                                    className='mx-1 mx-md-3 mb-5'
                                                                                    value='2'
                                                                                    onChange={(e) => handleChangePrecourse(index, e.target.value)}
                                                                                />
                                                                                <Form.Check
                                                                                    type="radio"
                                                                                    id={`default-radio-3`}
                                                                                    name={index}
                                                                                    className='mx-1 mx-md-3 mb-5'
                                                                                    value='3'
                                                                                    onChange={(e) => handleChangePrecourse(index, e.target.value)}
                                                                                />
                                                                                <Form.Check
                                                                                    type="radio"
                                                                                    id={`default-radio-4`}
                                                                                    name={index}
                                                                                    className='mx-1 mx-md-3 mb-5'
                                                                                    value='4'
                                                                                    onChange={(e) => handleChangePrecourse(index, e.target.value)}
                                                                                />
                                                                                <Form.Check
                                                                                    type="radio"
                                                                                    id={`default-radio-5`}
                                                                                    name={index}
                                                                                    className='mx-1 mx-md-3 mb-5'
                                                                                    value='5'
                                                                                    onChange={(e) => handleChangePrecourse(index, e.target.value)}
                                                                                />
                                                                            </div>
                                                                            <h5>Sangat Percaya Diri</h5>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return null;
                                                                }
                                                            })()}
                                                        </Form.Group>
                                                    </div>
                                                ))}
                                                <div className='w-100 mt-5 d-flex justify-content-start justify-content-md-end'>
                                                    <Button type='submit' className='black-button'>{t('next')} <img src={ArrowRight} alt='arrow' /></Button>
                                                </div>
                                            </Form>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                </Row>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body className='p-5'>
                    <h5>{t('precourse.finish')}</h5>
                    <Button className='black-button mt-4' onClick={handleClose}>
                        {t('letsgo')}
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    )
}
