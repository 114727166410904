import React, { useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AuthContext } from '../../App';
import JourneyNavigationBar from "../../components/JourneyNavigationBar";
import { Tabs, Tab, Col, Container, Row, Card } from "react-bootstrap";
import checklist from '../../assets/images/subscription/checklist.svg';
import "./Subscription.scss";

export default function SubscriptionIndex() {
    const { state } = useContext(AuthContext);
    const urlPendaftaranBadanSekolah = 'https://forms.gle/e6yAU6UUQQ8MAoEj7';
    const CardBulan = () => {
        return (
            <div className='d-flex flex-column flex-md-row'>
                <Card className='subscription-card'>
                    <div>
                        <div className='subscription-card-top'>
                            <div className='subscription-card-top-left'>
                                <div>Perorangan</div>
                                <div>80% Promo dari value 150.000/bulan </div>
                            </div>
                            <div className='subscription-card-top-right'>
                                <div>25.000</div>
                                <div>per bulan</div>
                            </div>
                        </div>
                        <div className='subscription-card-content'>
                            <p>Dalam 1 bulan, anda mendapatkan :</p>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>3 topik pembelajaran inovatif bersertifikasi</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Sertifikat</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>1x sesi mentoring</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>1x sesi berjaring dengan perusahaan/industri</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Akses ke semua perangkat pendukung pembelajaran</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Akses ke koleksi strategi teruji dan praktik baik pendidikan</span>
                            </div>
                        </div>
                    </div>
                    <Link to='/payment-method?packet=1' className='subscribe-button'>Pilih paket ini</Link>
                </Card>
                <Card className='subscription-card'>
                    <div>
                        <div className='subscription-card-top'>
                            <div className='subscription-card-top-left'>
                                <div>Badan Sekolah</div>
                                <div>85% Promo dari value 3.000.000 </div>
                            </div>
                            <div className='subscription-card-top-right'>
                                <div>460.000</div>
                                <div>per bulan</div>
                                <div>(senilai 23.000/user/bulan!)</div>
                            </div>
                        </div>
                        <div className='subscription-card-content'>
                            <p>Dalam 1 bulan, anda mendapatkan :</p>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>3 topik pembelajaran inovatif bersertifikasi</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Sertifikat</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>1x sesi mentoring</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>1x sesi berjaring dengan perusahaan/industri</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Akses ke semua perangkat pendukung pembelajaran</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Akses ke koleksi strategi teruji dan praktik baik pendidikan</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Sampai dengan 20 username/guru</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Laporan bulanan per sekolah</span>
                            </div>
                        </div>
                    </div>
                    <Link to={{ pathname: urlPendaftaranBadanSekolah }} target='_blank' className='subscribe-button'>Pilih paket ini</Link>
                </Card>
            </div>
        )
    }

    const CardSemester = () => {
        return (
            <div className='d-flex flex-column flex-md-row'>
                <Card className='subscription-card'>
                    <div>
                        <div className='subscription-card-top'>
                            <div className='subscription-card-top-left'>
                                <div>Perorangan</div>
                                <div>80% Promo dari value 900.000/6 bulan </div>
                            </div>
                            <div className='subscription-card-top-right'>
                                <div>138.000</div>
                                <div>138.000</div>
                                <div>(hanya 18.000/bulan!)</div>
                            </div>
                        </div>
                        <div className='subscription-card-content'>
                            <p>Dalam 6 bulan, anda mendapatkan :</p>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>3 topik pembelajaran inovatif bersertifikasi</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Sertifikat</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>6x sesi mentoring</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>6x sesi berjaring dengan perusahaan/industri</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Akses ke semua perangkat pendukung pembelajaran</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Akses ke koleksi strategi teruji dan praktik baik pendidikan</span>
                            </div>
                        </div>
                    </div>
                    <Link to='/payment-method?packet=2' className='subscribe-button'>Pilih paket ini</Link>
                </Card>
                <Card className='subscription-card'>
                    <div>
                        <div className='subscription-card-top'>
                            <div className='subscription-card-top-left'>
                                <div>Badan Sekolah</div>
                                <div>90% Promo dari value 18.000.000 </div>
                            </div>
                            <div className='subscription-card-top-right'>
                                <div>2.400.000</div>
                                <div>138.000</div>
                                <div>(hanya 18.000/bulan!)</div>
                            </div>
                        </div>
                        <div className='subscription-card-content'>
                            <p>Dalam 6 bulan, anda mendapatkan :</p>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>6 topik pembelajaran inovatif bersertifikasi</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Sertifikat</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>12x sesi mentoring</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>12x sesi berjaring dengan perusahaan/industri</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Akses ke semua perangkat pendukung pembelajaran</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Akses ke koleksi strategi teruji dan praktik baik pendidikan</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Sampai dengan 20 username/guru</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Laporan bulanan per sekolah</span>
                            </div>
                        </div>
                    </div>
                    <Link to={{ pathname: urlPendaftaranBadanSekolah }} target='_blank' className='subscribe-button'>Pilih paket ini</Link>
                </Card>
            </div>
        )
    }

    const CardTahun = () => {
        return (
            <div className='d-flex flex-column flex-md-row'>
                <Card className='subscription-card'>
                    <div>
                        <div className='subscription-card-top'>
                            <div className='subscription-card-top-left'>
                                <div>Perorangan</div>
                                <div>90% Promo dari value 900.000</div>
                            </div>
                            <div className='subscription-card-top-right'>
                                <div>240.000</div>
                                <div>per tahun</div>
                                <div>(hanya 20.000/bulan!)</div>
                            </div>
                        </div>
                        <div className='subscription-card-content'>
                            <p>Dalam 12 bulan, anda mendapatkan :</p>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>6 topik pembelajaran inovatif bersertifikasi</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Sertifikat</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>12x sesi mentoring</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>12x sesi berjaring dengan perusahaan/industri</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Akses ke semua perangkat pendukung pembelajaran</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Akses ke koleksi strategi teruji dan praktik baik pendidikan</span>
                            </div>
                        </div>
                    </div>
                    <Link to='/payment-method?packet=3' className='subscribe-button'>Pilih paket ini</Link>
                </Card>
                <Card className='subscription-card'>
                    <div>
                        <div className='subscription-card-top'>
                            <div className='subscription-card-top-left'>
                                <div>Badan Sekolah</div>
                                <div>90% Promo dari value 36.000.000 </div>
                            </div>
                            <div className='subscription-card-top-right'>
                                <div>4.300.000</div>
                                <div>per tahun</div>
                                <div>(senilai 18.000/user/bulan!)</div>
                            </div>
                        </div>
                        <div className='subscription-card-content'>
                            <p>Dalam 12 bulan, anda mendapatkan :</p>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>6 topik pembelajaran inovatif bersertifikasi</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Sertifikat</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>12x sesi mentoring</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>12x sesi berjaring dengan perusahaan/industri</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Akses ke semua perangkat pendukung pembelajaran</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Akses ke koleksi strategi teruji dan praktik baik pendidikan</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Sampai dengan 20 username/guru</span>
                            </div>
                            <div>
                                <img src={checklist} alt='checklist' />
                                <span>Laporan bulanan per sekolah</span>
                            </div>
                        </div>
                    </div>
                    <Link to={{ pathname: urlPendaftaranBadanSekolah }} target='_blank' className='subscribe-button'>Pilih paket ini</Link>
                </Card>
            </div>
        )
    }

    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }
    return (
        <>
            <JourneyNavigationBar />
            <div className='content-wrapper subscription'>
                <div className='subscription__content-wrapper'>
                    <Container>
                        <Row>
                            <Col className='d-flex flex-column justify-content-center'>
                                <Tabs defaultActiveKey="semester" className="mt-5 pricing-tabs">
                                    <Tab eventKey="bulan" title="Bayar per bulan">
                                        <CardBulan />
                                    </Tab>
                                    <Tab eventKey="semester" title="Bayar per semester">
                                        <CardSemester />
                                    </Tab>
                                    <Tab eventKey="tahun" title="Bayar per tahun">
                                        <CardTahun />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
