import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { AuthContext } from '../../App';
import { Redirect, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Row, Col, Accordion, Card, Spinner } from 'react-bootstrap';
import NavigationBar from '../../components/NavigationBar';
import './PaymentMethod.scss';

import TetrisDecoration1 from '../../assets/images/module/tetris-decoration-1.png';
import TetrisDecoration2 from '../../assets/images/module/tetris-decoration-2.png';

export default function PaymentMethodIndex() {
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [request, setRequest] = useState({});
    const [loading, setLoading] = useState(false);
    const { state, dispatch } = useContext(AuthContext);
    const { search } = useLocation();
    const { packet } = queryString.parse(search);
    const packetType = packet;

    const fetchData = () => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}api/payment_method`, config)
            .then(res => {
                setPaymentMethod(res.data);
                setLoading(true);
            })
            .catch(e => {
                console.log(e)
            })
    }

    const timeout = () => {
        setTimeout(() => {
            console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    const requestTransaction = (paymentCode) => {
        localStorage.setItem("payment_method", paymentCode);

        if (packetType === '1') {
            const packetDetail = {
                month: 1,
                payment_amount: 25000,
                payment_method: localStorage.getItem('payment_method')
            }
            setRequest(packetDetail);
        } else if (packetType === '2') {
            const packetDetail = {
                month: 6,
                payment_amount: 138000,
                payment_method: localStorage.getItem('payment_method')
            }
            setRequest(packetDetail);
        } else {
            const packetDetail = {
                month: 12,
                payment_amount: 240000,
                payment_method: localStorage.getItem('payment_method')
            }
            setRequest(packetDetail);
        }

        const requestBody = request;
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}api/request_transaction`, requestBody, config)
            .then(response => {
                if (response.data.http_code === 200) {
                    window.location.href = response.data.payment_url;
                }
            })
            .catch(error => {
                console.log(error.response.data);
            });
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
        timeout()
        // eslint-disable-next-line
    }, [])

    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    console.log(request)

    return (
        <>
            <NavigationBar />
            <div className='content-wrapper payment-method'>
                <div className='payment-method__content-wrapper'>
                    <Row className='d-flex justify-content-center'>
                        <Col md={6}>
                            <h3 className='text-center my-5 pt-3'>Pilih Metode Pembayaran</h3>
                            <Accordion className='payment-method__accordion'>
                                {loading ?
                                    <>
                                        {
                                            paymentMethod.map((pm, i) =>
                                                <Card key={i}>
                                                    <Accordion.Toggle as={Card.Header} eventKey={pm.id}>
                                                        {pm.name}
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6 9L12 15L18 9" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey={pm.id}>
                                                        <Card.Body>
                                                            {
                                                                paymentMethod[i].payment_methods.map((items, i) =>
                                                                    <div key={i} className='payment-method-item' onClick={() => requestTransaction(items.code)}>{items.name}
                                                                        <div className='payment-method-item-right'>
                                                                            <img src={window.location.origin + `/assets/images/payment/${items.code}.svg`} alt='payment method logo' />
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M5 12H19" stroke="#E30377" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M12 5L19 12L12 19" stroke="#E30377" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            )
                                        }
                                    </>
                                    :
                                    <div className='w-100 d-flex justify-content-center mt-3'>
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                }

                            </Accordion>
                        </Col>
                    </Row>
                </div>
                <div className='tetris-decoration'>
                    <img alt='tetris' src={TetrisDecoration1} />
                    <img alt='tetris' src={TetrisDecoration2} />
                </div>
            </div>
        </>
    )
}
