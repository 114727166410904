import { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { BsFileEarmarkArrowUp } from "react-icons/bs";
import { AiFillCheckCircle } from "react-icons/ai";
import { useTranslation } from 'react-i18next';

const UploadForm = ({ token, journeyID, subtitle, accept, uploadURL, bodyName, onSubmit, submited }) => {
  const [notAcceptedFileError, setNotAcceptedFileError] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const { t } = useTranslation();

  // specify upload params and url for your files
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();

    body.append("journey_id", journeyID);
    body.append(bodyName, file);
    return {
      url: uploadURL,
      headers: { Authorization: "Bearer " + token },
      body,
    };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "rejected_file_type") {
      setNotAcceptedFileError(true);
    }
    if (status === "preparing") {
      if (notAcceptedFileError) {
        setNotAcceptedFileError(false);
      }
    }
    if (status === "done") {
      setIsDone(true);
    }
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
    if(onSubmit != null){
      onSubmit()
    }
  };

  const NoInputLayout = ({
    previews,
    submitButton,
    input,
    files,
    dropzoneProps,
  }) => {
    if(submited){
      setIsDone(true)
    }
    return (
      <div {...dropzoneProps}>
        {previews}

        {files.length === 0 && !isDone ? (
          <>
            {input}
            <BsFileEarmarkArrowUp
              color="#2484FF"
              style={{ fontSize: "5em", marginTop: "0.5em" }}
            />
            {/* <img style={{ maxWidth: "100px", marginTop:"1em" }} src={UploadIcon} /> */}
            <h1 style={{ textAlign: "center", marginTop: "1em" }}>
              {t('course.post.ulp.form.title')}{" "}
            </h1>
            <p>{subtitle}</p>
          </>
        ): null}

        {files.length > 0 && !isDone ? (
          <>
            <h1 style={{ textAlign: "center", marginTop: "1em" }}>
              {t('course.post.ulp.form.uploading')}
            </h1>
          </>
        ) : null}
        {notAcceptedFileError && (
          <p style={{ color: "red" }}>Wrong File Format</p>
        )}
        {isDone && (
          <>
            <AiFillCheckCircle
              color="green"
              style={{ fontSize: "5em", marginTop: "0.5em" }}
            />
            <h1 style={{ textAlign: "center", marginTop: "1em" }}>
              {t('course.post.ulp.form.uploaded')}
            </h1>
            {isDone && submitButton}
          </>
        )}
      </div>
    );
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      LayoutComponent={NoInputLayout}
      inputContent={null}
      maxFiles={1}
      onSubmit={handleSubmit}
      accept={accept}
    />
  );
};

export default UploadForm;
