import React, { useState, useContext } from 'react';
import {
    Container,
    Nav,
    Navbar,
    NavDropdown,
    DropdownButton,
    Dropdown
} from "react-bootstrap";
import { AuthContext } from '../App';
import './JourneyNavigationBar.scss';
import { useTranslation } from 'react-i18next';
import Logo from '../assets/images/navigationbar/gened-logo.svg';
import UserPlaceholder from '../assets/images/user-placeholder.jpeg';
import idFlag from '../assets/images/id-flag.svg';
import usaFlag from '../assets/images/usa-flag.svg';

function JourneyNavigationBar() {
    const [navbar, setNavbar] = useState(false);
    const [locale, setLocale] = useState('id');
    const { i18n } = useTranslation();
    const { dispatch } = useContext(AuthContext);

    const addNavbarShadow = () => {
        if (window.scrollY > 0) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', addNavbarShadow)

    const handleSelect = (e) => {
        setLocale(e);
        i18n.changeLanguage(e);
    }

    let navFlag;
    if (locale === "id") {
        navFlag = <img src={idFlag} alt='id flag' />
    } else {
        navFlag = <img src={usaFlag} alt='en flag' />
    }
    return (
        <Navbar
            className={navbar ? 'journey-custom-navbar journey-custom-navbar-onscroll' : 'journey-custom-navbar'}
            collapseOnSelect
            sticky="top"
            expand="lg"
            variant="light">
            <Container fluid>
                <a href="https://gened.info">
                    <img className='logo' alt='logo' src={Logo} />
                </a>
                <DropdownButton
                    alignRight
                    title={navFlag}
                    className="language-picker"
                    onSelect={handleSelect}
                >
                    <Dropdown.Item eventKey="id"><img src={idFlag} alt='id flag' /> Indonesian</Dropdown.Item>
                    <Dropdown.Item eventKey="en"><img src={usaFlag} alt='en flag' /> English</Dropdown.Item>
                </DropdownButton>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <div className='space-between-menu'>
                            <Nav.Link href='https://gened.info'>Home</Nav.Link>
                            <Nav.Link href='/dashboard'>Dashboard</Nav.Link>
                            <NavDropdown title="Program" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/module">Innovation Learning</NavDropdown.Item>
                                <NavDropdown.Item href="#library">Innovation Library</NavDropdown.Item>
                                <NavDropdown.Item disabled style={{opacity: 0.4}} href="#community">Innovation Community</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="#leaderboard">Leaderboard</Nav.Link>
                            <Nav.Link href="/subscription">Subscription</Nav.Link>
                        </div>

                        <NavDropdown className='profile-dropdown'
                            title={
                                <div className="user-profile">
                                    <img className="user-img"
                                        src={UserPlaceholder}
                                        alt="user pic"
                                    />
                                </div>
                            }
                            id="basic-nav-dropdown">

                            <NavDropdown.Item eventKey={1.1} href="#profile">Profile</NavDropdown.Item>
                            <NavDropdown.Item eventKey={1.1} onClick={() => dispatch({ type: "LOGOUT" })}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>

                {/* <Image id="collasible-nav-dropdown" className='user-img' src="https://static.bangkokpost.com/media/content/20200619/c1_1937552_200619122619.jpg" roundedCircle /> */}
            </Container>

        </Navbar>
    );
}

export default JourneyNavigationBar;
