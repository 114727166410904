import React, { useContext, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AuthContext } from '../../App';
import axios from 'axios';
import { Button, Form, InputGroup } from 'react-bootstrap';
import './Auth.scss';
import { useTranslation } from 'react-i18next';
import AuthSidebar from '../../components/AuthSidebar/AuthSidebar';
import FooterTetris from '../../assets/images/auth/footer-tetris.svg'

export default function ForgotPassword(props) {
    const { t } = useTranslation();
    const { state } = useContext(AuthContext);
    const initialState = {
        email: "",
        isSubmitting: false,
        errorMessage: null
    }
    const [data, setData] = useState(initialState);
    const handleInputChange = event => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }
    const handleFormSubmit = event => {
        event.preventDefault()
        setData({
            ...data,
            isSubmitting: true,
            errorMessage: null
        })
        const requestBody = {
            email: data.email
        }
        const config = {
            header: {
                'Content-Type': 'text/html; charset=UTF-8'
            }
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}api/request-password-reset`, requestBody, config)
            .then(response => {
                props.history.push("/reset-password-sent");
            })
            .catch(error => {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: "Email yang Anda masukkan tidak ditemukan."
                })
                console.log(error.response.data);
            });
    }

    if (state.isAuthenticated) {
        return <Redirect to="/dashboard" />
    }

    return (
        <div className='auth'>
            <div className='background-layer'>
                <AuthSidebar />
                <div className='auth-form'>
                    <div className='auth-form-title'>{t('slogan.title')}</div>
                    <div className='auth-form-subtitle'>
                        <span>{t('forgot.password')}</span>
                    </div>
                    <Form onSubmit={handleFormSubmit} className='form-wrapper'>
                        <Form.Label>Email</Form.Label>
                        <InputGroup className='mb-3'>
                            <Form.Control name="email" value={data.email} onChange={handleInputChange} />
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.2857 2.14282H0.714286C0.319196 2.14282 0 2.46202 0 2.85711V17.1428C0 17.5379 0.319196 17.8571 0.714286 17.8571H19.2857C19.6808 17.8571 20 17.5379 20 17.1428V2.85711C20 2.46202 19.6808 2.14282 19.2857 2.14282ZM18.3929 4.61604V16.25H1.60714V4.61604L0.991071 4.13613L1.8683 3.00889L2.82366 3.7522H17.1786L18.1339 3.00889L19.0112 4.13613L18.3929 4.61604V4.61604ZM17.1786 3.74996L10 9.33032L2.82143 3.74996L1.86607 3.00666L0.988839 4.13389L1.60491 4.6138L9.22991 10.5424C9.44919 10.7127 9.71897 10.8052 9.99665 10.8052C10.2743 10.8052 10.5441 10.7127 10.7634 10.5424L18.3929 4.61604L19.0089 4.13613L18.1317 3.00889L17.1786 3.74996Z" fill="black" />
                            </svg>
                        </InputGroup>
                        <Button className='w-100 auth-button' variant="danger" type="submit" disabled={data.isSubmitting}>
                            {data.isSubmitting ? (
                                "Loading.."
                            ) : (
                                "Reset Password"
                            )}
                        </Button>
                        {data.errorMessage && (
                            <div className='warning-label'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 0C4.47768 0 0 4.47768 0 10C0 15.5223 4.47768 20 10 20C15.5223 20 20 15.5223 20 10C20 4.47768 15.5223 0 10 0ZM10 18.3036C5.41518 18.3036 1.69643 14.5848 1.69643 10C1.69643 5.41518 5.41518 1.69643 10 1.69643C14.5848 1.69643 18.3036 5.41518 18.3036 10C18.3036 14.5848 14.5848 18.3036 10 18.3036Z" fill="#FFBA26" />
                                    <path d="M8.92871 13.9286C8.92871 14.2127 9.04159 14.4853 9.24253 14.6862C9.44346 14.8871 9.71599 15 10.0002 15C10.2843 15 10.5568 14.8871 10.7578 14.6862C10.9587 14.4853 11.0716 14.2127 11.0716 13.9286C11.0716 13.6444 10.9587 13.3719 10.7578 13.171C10.5568 12.97 10.2843 12.8571 10.0002 12.8571C9.71599 12.8571 9.44346 12.97 9.24253 13.171C9.04159 13.3719 8.92871 13.6444 8.92871 13.9286ZM9.46443 11.4286H10.5359C10.6341 11.4286 10.7145 11.3482 10.7145 11.25V5.17857C10.7145 5.08036 10.6341 5 10.5359 5H9.46443C9.36622 5 9.28586 5.08036 9.28586 5.17857V11.25C9.28586 11.3482 9.36622 11.4286 9.46443 11.4286Z" fill="#FFBA26" />
                                </svg>
                                {t('email.not.found')}
                            </div>
                        )}
                        <div className='auth-divider' />
                        <div className='cta-label'>
                            <span>{t('remember.password')}</span>
                            <Link to='/'>{t('sign.in')}</Link>
                        </div>
                    </Form>
                    <img className='auth-footer-tetris' src={FooterTetris} alt='footer tetris' />
                </div>
            </div>
        </div>
    )
}
