import React from "react";
import i18n from 'i18next';

class Next extends React.Component {

  render() {
    const { isActive, className } = this.props;
    const classname = `blue-button ${className}`
    if (isActive === false) return null;

    return (
      <button className={classname} onClick={() => this.props.goToNextStep()}>
        {i18n.t('next')}
      </button>
    );
  }
}

export { Next };
