import React, { useEffect, useContext } from 'react';
import Lottie from 'react-lottie';
import { AuthContext } from '../../App';
import queryString from 'query-string';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import NavigationBar from '../../components/NavigationBar';
import './PaymentStatus.scss';
import PendingImg from '../../assets/images/payment/pending.svg';
import TetrisDecoration1 from '../../assets/images/module/tetris-decoration-1.png';
import TetrisDecoration2 from '../../assets/images/module/tetris-decoration-2.png';
import SuccessLottie from '../../assets/lottie/59133-success.json';

export default function PaymentMethodIndex() {
    const { state, dispatch } = useContext(AuthContext);
    const { search } = useLocation();
    const { resultCode } = queryString.parse(search)
    const paymentStatus = resultCode;

    const timeout = () => {
        setTimeout(() => {
            console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    useEffect(() => {
        timeout()
        // eslint-disable-next-line
    }, [])

    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: SuccessLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const PaymentSuccess = () => {
        if (paymentStatus === '00') {
            return (
                <Col className='py-5 d-flex flex-column justify-content-center'>
                    <h3 className='text-center'>Yayy!</h3>
                    <h3 className='text-center'>Pembayaranmu berhasil 🙌 </h3>
                    <Lottie options={defaultOptions}
                        height={400}
                        width={400} />
                    <Link to='/module' className='black-button'>Lanjut Belajar</Link>
                </Col>
            )
        } else if (paymentStatus === '01') {
            return (
                <Col className='py-5 d-flex flex-column justify-content-center'>
                    <h3 className='text-center'>Pembayaran Anda tertunda</h3>
                    <h3 className='text-center'>Mohon tunggu beberapa saat lagi 🙏</h3>
                    <img src={PendingImg} alt='pending img' className='pending-image py-5' />
                </Col>
            )
        } else {
            return (
                <Col className='px-3 py-5 d-flex flex-column justify-content-center'>
                    <h3 className='text-center'>Wahh, pembayaran kamu gagal nih 🤔</h3>
                    <h3 className='text-center'>Coba metode pembayaran lain yuk</h3>
                    <Link to='/subscription' className='black-button mt-4'>Coba lagi</Link>
                </Col>
            )
        }
    }

    return (
        <>
            <NavigationBar />
            <div className='content-wrapper payment-status'>
                <div className='payment-status__content-wrapper'>
                    <Row className='my-5'>
                        <PaymentSuccess />
                    </Row>
                </div>
                <div className='tetris-decoration'>
                    <img alt='tetris' src={TetrisDecoration1} />
                    <img alt='tetris' src={TetrisDecoration2} />
                </div>
            </div>
        </>
    )
}
