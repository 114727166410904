import React, { useEffect, useContext } from 'react'
import { AuthContext } from '../../App';
import { Redirect } from 'react-router-dom';
import NavigationBanner from '../../components/Dashboard/NavigationBanner';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InnovativeLearning from './../../assets/images/dashboard/innovative-learning.jpg';
import InnovationLibrary from './../../assets/images/dashboard/innovation-library.svg';
import Network from './../../assets/images/dashboard/network.svg';

import './Dashboard.scss';
import DashboardSidebar from '../../components/Dashboard/DashboardSidebar/DashboardSidebar';


export default function Dashboard() {
  const { state, dispatch } = useContext(AuthContext)
  const { t } = useTranslation();

  const timeout = () => {
    setTimeout(() => {
      console.log("Token telah berakhir")
      dispatch({
        type: "LOGOUT"
      })
    }, state.tokenExpires)
  }

  useEffect(() => {
    timeout()
    // eslint-disable-next-line
  }, [])

  if (!state.isAuthenticated) {
    return <Redirect to="/" />
  }

  return (
    <div className='dashboard-wrapper'>
      <DashboardSidebar />
      <div className='content-wrapper banner'>
        <div className='banner__content-wrapper'>
          <Row>
            <h1>{t('dashboard.header')}</h1>
            <Col>
              <div className='row-center'>
                <div className='banner__content'>
                  <Row className='wrap-content mt-2'>
                    <Col>
                      <NavigationBanner
                        title={t('dashboard.learn')}
                        url={"/module"}
                        img={InnovativeLearning}
                        isActive
                      />
                    </Col>
                    <Col>
                      <NavigationBanner
                        title={t('dashboard.library')}
                        url={"https://library.gened.info"}
                        img={InnovationLibrary}
                        isActive
                      />
                    </Col>
                    <Col>
                      <NavigationBanner
                        title={t('dashboard.network')}
                        url={"https://community.gened.info"}
                        img={Network}
                        isActive
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}