import React from "react";
import i18n from 'i18next';

class Submit extends React.Component {

  render() {
    const { isActive } = this.props;
    if (isActive === false) return null;

    return (
      <button type="submit">
        {i18n.t('submit')}
      </button>
    );

  }
}

export { Submit };
