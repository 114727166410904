import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, Redirect } from 'react-router-dom'
import { AuthContext } from '../../App';
import axios from 'axios';
import './Journey.scss';
import '../../components/Modal/Modal.scss';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  Row,
  Col,
  Button,
  Card,
  Spinner
} from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import JourneyNavigationBar from '../../components/JourneyNavigationBar';
import BackButton from '../../components/Button/BackButton';

import Tetris from '../../assets/images/journey/tetris.svg';
import TetrisDone from '../../assets/images/journey/tetris-done.svg';
import X from '../../assets/images/journey/x.svg';

function JourneyModal(props) {
  const { t } = useTranslation();
  const [course, setCourse] = useStateWithCallbackLazy(0);
  const scrollPoint = useRef();

  const selectCourse = (course) => {
    setCourse(course, () => {
      scrollPoint.current.scrollIntoView({ behavior: 'smooth' })
    });
  }

  return (
    <div className="custom-modal">
      <div className='custom-modal-content'>
        <img alt='close' className='close-button' onClick={() => props.handleModalClose()} src={X} />
        <h1>{props.modalJourneyDetail.journey_name}</h1>
        <p>{props.modalJourneyDetail.about}</p>
        <div className='information-literacy-details'>
          <div className='information-literacy-details__item'>
            {props.modalJourneyDetail.courses.length} Micro Courses
          </div>
          <div className='information-literacy-details__item'>
            {t('journey.duration')}
          </div>
          <div className='information-literacy-details__item'>
            {t('journey.explanation.applicable')}
          </div>
          <div className='information-literacy-details__item'>
            {t('journey.explanation.certificate')}
          </div>
          <div className='rating-container'>
            <div>
              {t('journey.rating')}
            </div>
            <div>
              <div className='rating-wrapper'>
                <div className='rating-item rating-item-active'></div>
                <div className='rating-item rating-item-active'></div>
                <div className='rating-item rating-item-active'></div>
                <div className='rating-item rating-item-active'></div>
                <div className='rating-item'></div>
              </div>
              <div>
                (128 {t('journey.review')})
              </div>
            </div>
          </div>
        </div>
        <div className='divider' />
        <div className='teaching-journey'>
          <h3>{t('journey.header')}</h3>
          <div className='tetris-block-0'>
            <div />
            <div />
            <div />
            <div />
          </div>
          <div className='vertical-line' />
          <Link to={"/journey/" + props.modalJourneyDetail.journey_id + "/precourse"}>
            <div className='black-block'>{t('journey.survey')}</div>
          </Link>
          <div className='vertical-line' />
          <div className='row-divider'>
            <div className='line-divider' />
          </div>
          <div className='teaching-journey__row-1'>
            {props.modalJourneyDetail.courses.map((course, i) => {
              return (
                <div key={i} className='teaching-journey__step-wrapper' onClick={() => { selectCourse(course) }}>
                  <div key={course.id} className='step-number'>{i + 1}</div>
                  <div className={`tetris-block-${i + 1}`}>
                    <div className={`tetris-block-${i + 1}__row-1`}>
                      <div />
                      <div />
                      <div />
                    </div>
                    <div className={`tetris-block-${i + 1}__row-2`}>
                      <div />
                      <div />
                      <div />
                    </div>
                  </div>
                  <span>{course.course_name}</span>
                </div>
              )
            })}
          </div>
          <div className='d-flex flex-column flex-lg-row align-items-center justify-content-start w-100'>
            <Link to={"/journey/" + props.modalJourneyDetail.journey_id + "/microcourse/final"}>
              <div className='black-block praktikum-btn'>{t('journey.practice')}</div>
            </Link>
            <div className='d-flex flex-column justify-content-center tetris-done-wrapper'>
              <img className='mb-2' src={TetrisDone} alt='tetris' />
              <h3>{t('journey.done')}</h3>
            </div>
          </div>
        </div>
        {course ?
          <div ref={scrollPoint} className='journey-step-action-wrapper'>
            <h3>{course.course_name}</h3>
            <p>{course.about}</p>
            <Link to={`/microcourse/${course.course_id}`}>
              <Button className='small-black-button'>{t('journey.start')}</Button>
            </Link>
          </div>
          : null}
      </div>
    </div>
  )
}

function IndexJourney(props) {
  const { state, dispatch } = useContext(AuthContext);
  const [module, setModule] = useState({});
  const [journey, setJourney] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowModel, setIsShowModel] = useState(false);
  const [modalJourneyDetail, setModalJourneyDetail] = useState({});

  const fetchData = () => {
    var config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + state.token
      }
    }

    axios.get(`${process.env.REACT_APP_BASE_URL}api/v2/master/id/module/` + props.match.params.id + `/journey`, config)
      .then(res => {
        setModule(res.data.module);
        setJourney(res.data.journeys);
        setLoading(true);
      })
      .catch(e => {
        console.log(e)
      })
  }

  const timeout = () => {
    setTimeout(() => {
      console.log("Token telah berakhir")
      dispatch({
        type: "LOGOUT"
      })
    }, state.tokenExpires)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
    timeout()
    // eslint-disable-next-line
  }, [])

  if (!state.isAuthenticated) {
    return <Redirect to="/" />
  }

  const handleShow = (j) => {
    setModalJourneyDetail(j);
    setIsShowModel(true);
  }

  const handleOnhide = () => {
    setIsShowModel(false);
  }

  return (
    <>
      <JourneyNavigationBar />
      <div className='content-wrapper journey'>
        <div className='journey__content-wrapper'>
          <Row>
            <Col md='12'>
            </Col>
            <Col md='12' style={{ marginBottom: 50, marginTop: 50 }}>
              <Link to='/module'>
                <BackButton />
              </Link>
            </Col>
            <Col md='4'>
              {loading ?
                <div className='journey-image'>
                  <img src={module.img_url} alt='module' />
                </div>
                : null}
            </Col>
            <Col md='8'>
              {loading ?
                <div className='journey-details'>
                  <h3>{module.module_name}</h3>
                  <p>{module.module_desc}</p>
                  <div className='journey-action'>
                    {/* <Button className='primary-button'>Start Quiz</Button> */}
                    <span>{Object.keys(journey).length} Journey</span>
                  </div>
                </div>
                : null}
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <div className='content-partial'>
                <h2>JOURNEYS</h2>
                <div className='tetris-decoration'>
                  <img src={Tetris} alt='tetris' />
                </div>
              </div>
            </Col>
            {loading ?
              <>
                {journey.map(j =>
                  <Col md='6' key={j.journey_id} className='journey-column'>
                    <Card className='journey-card' onClick={() => { handleShow(j) }}>
                      <div className='journey-card__overlay' />
                      <img src={j.img_url} alt='journey img' />
                    </Card>
                    <div className='journey-title'>
                      <h5>{j.journey_name}</h5>
                    </div>
                  </Col>

                )}
              </>
              :
              <div className='w-100 d-flex justify-content-center mt-3'>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            }
          </Row>
        </div>
      </div>
      {
        isShowModel &&
        <JourneyModal
          size="xl"
          keyboard={false}
          className='custom-modal'
          showModel={isShowModel}
          modalJourneyDetail={modalJourneyDetail}
          handleModalClose={() => { handleOnhide() }}
        >
        </JourneyModal>
      }
    </>
  );
}

export default IndexJourney;
