import ReactPlayer from 'react-player'

function VideoPage({videoURL, onVideoEnded}) {
  return <>
    <div className='microcourse__video'>
      <ReactPlayer width='100%' height='100%' url={videoURL} controls={true} onEnded={onVideoEnded} />
    </div>
  </>
}

export default VideoPage