import ReactAudioPlayer from 'react-audio-player';
import { useTranslation } from 'react-i18next';

function PodcastPage({podcastURL, podcastImage, onPodcastEnded, speakers}) {
  const { t } = useTranslation();

  return <>
    <div className='microcourse__podcast'>
      <div className='podcast-content'>
        <img src={podcastImage} alt='podcast' className='podcast-image' />
        <div className='podcast-player'>
          <h1>How do we build Guided Reading?</h1>
          <ReactAudioPlayer
            className='podcast-audio-player'
            src={podcastURL}
            controls
            onEnded={onPodcastEnded}
          />
        </div>
      </div>
      <div className='speaker-title'>{t('course.podcast.speaker')}:</div>
      <div className='podcast-speaker'>
        {speakers.map((speaker, i) => (
          <div className='speaker' key={i}>
            <img src={speaker.podcast_speaker.img_url} alt='speaker' className='speaker-photo' />
            <div className='speaker-detail'>
              <div className='speaker-name'>
                {speaker.podcast_speaker.name}
              </div>
              <div className='speaker-job'>
                {speaker.podcast_speaker.about}
              </div>
            </div>
          </div>
        ))}

      </div>
    </div>
  </>
}
export default PodcastPage