import React, { useContext, useEffect, useState } from 'react'
import { Nav } from 'react-bootstrap'
import './DashboardSidebar.scss'
import IconTetrisBlue from '../../../assets/images/dashboard/icon-tetris-blue.svg'
import IconTetrisGreen from '../../../assets/images/dashboard/icon-tetris-green.svg'
import IconTetrisYellow from '../../../assets/images/dashboard/icon-tetris-yellow.svg'
import IconTetrisPink from '../../../assets/images/dashboard/icon-tetris-pink.svg'
import IconHome from '../../../assets/images/dashboard/icon-home.svg'
import IconLeaderboard from '../../../assets/images/dashboard/icon-leaderboard.svg'
import IconProfile from '../../../assets/images/dashboard/icon-profile.svg'
import ChevronLeft from '../../../assets/images/dashboard/chevron-left.svg'
import Logo from '../../../assets/images/dashboard/gened-logo.svg'
import Menu from '../../../assets/images/dashboard/menu.svg'
import useBreakpoint from '../../Hooks/useBreakpoint'
import { AuthContext } from '../../../App'
import { HiOutlineUserCircle, HiOutlineLogout } from "react-icons/hi";
import { atom, useRecoilState } from 'recoil';
import useFetch from '../../../services/useFetch'

export default function DashboardSidebar() {
    const breakpoint = useBreakpoint();
    const [collapse, setCollapse] = useState();
    const { dispatch } = useContext(AuthContext);
    const { data, loading, error } = useFetch(`${process.env.REACT_APP_BASE_URL}api/user/detail`)

    const userDetailState = atom({
        key: 'userDetailState',
        default: {},
    })
    const [userDetail, setUserDetail] = useRecoilState(userDetailState);
    const firstName = userDetail?.user_detail?.first_name;
    const lastName = userDetail?.user_detail?.last_name;

    useEffect(() => {
        setUserDetail(data)
    }, [data, setUserDetail])

    useEffect(() => {
        if (breakpoint === 'xs' || 'sm') {
            setCollapse(false)
        } else {
            setCollapse(true)
        }
    }, [breakpoint])

    //get url dynamically based on env
    const genedURL = process.env.REACT_APP_GENED_URL;
    const learnURL = process.env.REACT_APP_LEARN_URL + "module";
    const libraryURL = process.env.REACT_APP_LIBRARY_URL;
    const toggleCollapse = () => {
        setCollapse(!collapse);

        // Disable scroll when sidebar open
        if (collapse) {
            document.body.style.overflowY = "auto";
        } else {
            document.body.style.overflowY = "hidden";
        }
    }

    return (
        <div className='dashboard-sidebar-wrapper'>
            <div className='mini-sidebar-toggle' onClick={toggleCollapse}>
                <img src={Menu} alt='menu' />
                <span>Menu</span>
            </div>
            <div className={collapse ? 'dashboard-sidebar' : 'dashboard-sidebar dashboard-sidebar-collapse'}>
                <div className='dashboard-sidebar-toggle' onClick={toggleCollapse}>
                    <img src={ChevronLeft} alt='toggle sidebar' />
                    <span>Hide</span>
                </div>
                <a className='sidebar-logo' href={genedURL}>
                    <img src={Logo} alt='logo' />
                </a>
                <Nav>
                    <Nav.Item className='active'>
                        <Nav.Link href="/"><img src={IconHome} alt='home icon' /><span>Home</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link disabled style={{ opacity: 0.4 }}><img src={IconProfile} alt='profile icon' /><span>Profile</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link disabled style={{ opacity: 0.4 }}><img src={IconLeaderboard} alt='leaderboard icon' /><span>Leaderboard</span></Nav.Link>
                    </Nav.Item>
                    <div className='sidebar-divider' />
                    <span className='sidebar-subtitle'>PROGRAMS</span>
                    <Nav.Item>
                        <Nav.Link href={learnURL}><img src={IconTetrisBlue} alt='innovative learning icon' /><span>Innovation Learning</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href={libraryURL}><img src={IconTetrisGreen} alt='innovative library icon' /><span>Innovation Library</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link disabled style={{ opacity: 0.4 }}><img src={IconTetrisYellow} alt='innovative community icon' /><span>Innovation Community</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link disabled style={{ opacity: 0.4 }}><img src={IconTetrisPink} alt='innovative scholar icon' /><span>#InnovationScholar</span></Nav.Link>
                    </Nav.Item>
                    <div className='sidebar-divider' />
                    <Nav.Item>
                        <Nav.Link>
                            <HiOutlineUserCircle size={32} color='black' />
                            {loading && <span>{loading}</span>}
                            {data && <span>{firstName} {lastName}</span>}
                            {error && <span>{error}</span>}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => dispatch({ type: "LOGOUT" })}>
                        <Nav.Link>
                            <HiOutlineLogout size={32} color='black' />
                            <span>Logout</span>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
        </div>
    )
}
