import React from 'react';
import { Link } from 'react-router-dom';
import UnderConstructionIllustration from '../../../assets/images/under-construction/illustration.svg';
import './UnderConstruction.scss';

export default function UnderConstruction() {
    return (
        <div className='content-wrapper under-construction'>
            <div>
                <h1>Hi, halaman ini sedang dibangun.</h1>
                <p className='mb-5'>Tapi, jangan khawatir! Kamu masih bisa menjelajahi website ini ...</p>
                <Link to='/' className='pink-button'>Back to Homepage</Link>
            </div>
            <img src={UnderConstructionIllustration} alt='under construction logo' />
        </div>
    )
}
