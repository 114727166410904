import { Button, ProgressBar, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import NextBlueButton from "../../components/Button/NextBlueButton";
import BackBlueButton from "../../components/Button/BackBlueButton";

import TetrisPiece1 from "../../assets/images/microcourse/tetris-piece-1.svg";
import TetrisPiece2 from "../../assets/images/microcourse/tetris-piece-2.svg";
import TetrisPiece3 from "../../assets/images/microcourse/tetris-piece-3.svg";
import TetrisPiece4 from "../../assets/images/microcourse/tetris-piece-4.svg";
import TetrisPiece5 from "../../assets/images/microcourse/tetris-piece-5.svg";
import TetrisPiece6 from "../../assets/images/microcourse/tetris-piece-6.svg";

function SelfReflectionPage({
  selfReflection,
  activeCourseStep,
  activeSelfReflectionStep,
  totalSelfReflection,
  courseName,
  courseID,
  lastCourseID,
  selfReflectionDone,
  selfReflectionAnswer,
  onChangeSelfReflection,
  previousSelfReflectionStep,
  submitSelfReflectionAnswer,
  nextSelfReflectionStep,
  errorValidation,
}) {

  const { t } = useTranslation();

  const handleNextCourse = () => {
    if (lastCourseID === courseID) {
      window.open(`/module/1/journey`, '_self', 'noopener,noreferrer');
    } else {
      window.open(`/microcourse/${courseID + 1}`, '_self', 'noopener,noreferrer');
    }
  }

  const SelfReflectionQuestion = ({ sr, currentStep, index, goNextStep }) => {
    if (currentStep !== index) return null;
    return (
      <div>
        <h1 className="my-4 question">{t('course.sr.' + (index + 1))}</h1>
        <Form className="w-100 reflection-form">
          <Form.Group controlId="ControlTextarea1">
            <Form.Control
              disabled={selfReflectionDone}
              defaultValue={selfReflectionAnswer[index]}
              as="textarea"
              rows={10}
              onChange={(e) => onChangeSelfReflection(index, e.target.value)}
            />
          </Form.Group>
        </Form>
        <div className="button-group" style={{ marginTop: 20 }}>
          {currentStep > 0 && (
            <BackBlueButton onClick={previousSelfReflectionStep} />
          )}
          <div style={{ marginRight: 36 }} />
          {currentStep < totalSelfReflection - 1 && (
            <NextBlueButton onClick={goNextStep} />
          )}
          {currentStep === totalSelfReflection - 1 && (
            <div>
              <Button
                onClick={submitSelfReflectionAnswer}
                className="blue-button"
                style={{ width: 150 }}
              >
                {t('submit')}
              </Button>
            </div>
          )}
        </div>
        { errorValidation && <p style={{ color: "red" }}>Please Fill All Self Reflection</p>}
      </div>
    );
  };

  return (
    <div className="microcourse__quiz">
      {activeCourseStep === 3 && (
        <>
          <div className="reflection">
            <img
              className="tetris-decoration-1"
              src={TetrisPiece1}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-2"
              src={TetrisPiece2}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-3"
              src={TetrisPiece3}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-4"
              src={TetrisPiece4}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-5"
              src={TetrisPiece5}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-6"
              src={TetrisPiece6}
              alt="tetris piece"
            />
            <div>
              {t('course.quiz.question')} {activeSelfReflectionStep + 1}/{totalSelfReflection}
            </div>
            {selfReflection.map((sr, index) => (
              <SelfReflectionQuestion
                sr={sr}
                index={index}
                currentStep={activeSelfReflectionStep}
                key={sr.id}
                goNextStep={nextSelfReflectionStep}
              />
            ))}
          </div>
        </>
      )}
      {activeCourseStep === 4 && (
        <>
          <ProgressBar className="quiz-progressbar" now={100} />
          <div className="quiz-box quiz-box-gate">
            <img
              className="tetris-decoration-1"
              src={TetrisPiece1}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-2"
              src={TetrisPiece2}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-3"
              src={TetrisPiece3}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-4"
              src={TetrisPiece4}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-5"
              src={TetrisPiece5}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-6"
              src={TetrisPiece6}
              alt="tetris piece"
            />
            <h1 style={{ marginBottom: 28 }}>
              {t('course.completed')}
            </h1>
            <p>Microcourse - {courseName}</p>
            <Button className="blue-button" style={{ width: 235 }} onClick={handleNextCourse}>
              {t('course.nextstep')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
export default SelfReflectionPage;
