import React, { useState, useEffect, useContext, useReducer } from "react";
import { Link, Redirect } from "react-router-dom";
import { AuthContext } from '../../App';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './Microcourse.scss';
import {
  Row,
  Col,
  Button,
  // Modal
} from "react-bootstrap";
import JourneyNavigationBar from '../../components/JourneyNavigationBar';
import BackButton from '../../components/Button/BackButton';
import MicrocourseProgress from "./MicrocourseProgress";

import TetrisFooter from '../../assets/images/microcourse/tetris-footer.svg';
import ArrowRight from '../../assets/images/arrow-right.svg';
import ArrowLeft from '../../assets/images/arrow-left.svg';
import VideoPage from "./VideoPage";
import PodcastPage from "./PodcastPage";
import QuizPage from "./QuizPage";
import SelfReflectionPage from "./SelfReflectionPage";
// import SubscribeModalTetris from '../../assets/images/microcourse/subscribe-modal-tetris.svg';
import CorrectAnswerModal from "../../components/Modal/CorrectAnswerModal/CorrectAnswerModal";

function IndexMicrocourse(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [course, setCourse] = useState([]);
  const [lastCourseID, setLastCourseID] = useState({});
  const [allCourses, setAllCourses] = useState([])
  const [objective, setObjetive] = useState("")
  const [resources, setResources] = useState([])
  const [showObjective, setShowObjective] = useState(true)
  const [showResources, setShowResources] = useState(false)
  const [moduleID, setModuleID] = useState(0)
  const [speakers, setSpeakers] = useState([]);
  const [videoURL, setVideoURL] = useState('');
  const [podcastURL, setPodcastURL] = useState('');
  const [podcastImage, setPodcastImage] = useState('');
  const [currentUserProgress, setCurrentUserProgress] = useState({ last_step: 0, last_course: parseInt(props.match.params.id) })
  const [quiz, setQuiz] = useState([]);
  const [selfReflectionError, setSelfReflectionError] = useState(false)
  const [selfReflection, setSelfReflection] = useState([]);
  const [activeSelfReflectionStep, setActiveSelfReflectionStep] = useState(0);
  const [selfReflectionAnswer, setSelfReflectionAnswer] = useState([])
  const [selfReflectionDone, setSelfReflectionDone] = useState(false)
  const [activeCourseStep, setActiveCourseStep] = useState(0)
  const [showCorrectAnswerModal, setShowCorrectAnswerModal] = useState(false)
  const [quizAnswerSelected, setQuizAnswerSelected] = useState([])

  const [activeQuizStep, setActiveQuizStep] = useState(0);
  const [quizScore, setQuizScore] = useState(0)
  const [isQuizAnswerSubmited, setIsQuizAnswerSubmited] = useState([])
  const [podcastTranscripts, setPodcastTranscripts] = useState([])
  const [videoTranscripts, setVideoTranscripts] = useState([])
  const [activeranscriptStep, setActiveTranscriptStep] = useState(0)
  const [videoDone, setVideoDone] = useState(false)
  const [podcastDone, setPodcastDone] = useState(false)
  const [quizPassed, setQuizPassed] = useState(false)
  const [quizStatus, setQuizStatus] = useState({})
  const [menuCourseSteOverwrite, setMenuCourseSteOverwrite] = useState(false)
  const { state, dispatch } = useContext(AuthContext);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  // const [setSubscriptionStatus] = useState(false);
  // const [showSubscribeModal, setShowSubscribeModal] = useState(true);
  // const [microcourseId, setMicrocourseId] = useState('');
  // const [firstCoursePerJourney, setFirstCoursePerJourney] = useState('');
  // const [modalSubscription, setModalSubscription] = useState(false);
  const [courseName, setCourseName] = useState('');
  const [courseIndex, setCourseIndex] = useState('');

  const { t } = useTranslation();
  const objectiveActiveStyle = showObjective ? "menu-item-active" : ""
  const resourceseActiveStyle = showResources ? "menu-item-active" : ""

  // const handleCloseSubscribeModal = () => setShowSubscribeModal(false);

  function goToSpecificStepCourse(step) {
    if (step <= currentUserProgress.last_step && currentUserProgress.last_course === parseInt(props.match.params.id)) {
      setMenuCourseSteOverwrite(true)
      setActiveCourseStep(step)
      setActiveTranscriptStep(0)
    }
    if (parseInt(props.match.params.id) < currentUserProgress.last_course) {
      setMenuCourseSteOverwrite(true)
      setActiveCourseStep(step)
      setActiveTranscriptStep(0)
    }
  }

  function nextSelfReflectionStep() {
    const selfReflectionStep = activeSelfReflectionStep + 1
    setActiveSelfReflectionStep(selfReflectionStep)
    forceUpdate()
  }

  function previousSelfReflectionStep() {
    setSelfReflectionError(false)
    const selfReflectionStep = activeSelfReflectionStep - 1
    setActiveSelfReflectionStep(selfReflectionStep)
    forceUpdate()
  }

  function onChangeSelfReflection(index, value) {
    let answers = selfReflectionAnswer
    answers[index] = value
    setSelfReflectionAnswer(answers)
  }

  function sortTranscripts(trasncripts) {
    trasncripts.sort(function (transcriptA, transcriptB) {
      return transcriptA.order - transcriptB.order
    })
    return trasncripts
  }

  function submitSelfReflectionAnswer() {
    const activeStep = activeCourseStep
    var config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + state.token
      }
    }
    let payload = {}
    const srAnswer = selfReflectionAnswer
    let error = false
    if (srAnswer.length !== selfReflection.length) {
      setSelfReflectionError(true)
      return null
    }
    for (let i = 0; i < srAnswer.length; i++) {
      if (!srAnswer[i]) {
        error = true
        break;
      }
    }
    if (error) {
      setSelfReflectionError(true)
      return null
    }
    let srPayload = srAnswer.map((answer, index) => {
      let tempAnswer = {}
      tempAnswer["self_reflection_id"] = selfReflection[index].self_reflection_id
      tempAnswer["answer"] = answer
      return tempAnswer;
    })
    payload["course_id"] = props.match.params.id
    payload["user_self_reflection_answers"] = srPayload
    if (selfReflectionDone) {
      setActiveCourseStep(activeStep + 1)
    } else {
      axios.post(`${process.env.REACT_APP_BASE_URL}api/self_reflection`, payload, config)
        .then(function (res) {
          sendUserProgress()
          setActiveCourseStep(activeStep + 1)
          setSelfReflectionDone(true)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  // function validationCourse() {
  //   let index = allCourses.findIndex(c => c.course_id === parseInt(currentUserProgress.last_course));
  //   if (currentUserProgress.last_step === 4 && props.match.params.id > allCourses[index + 1].course_id) {
  //     history.push(`/module/${moduleID}/journey`)
  //   } else if (currentUserProgress.last_step < 4 && props.match.params.id > allCourses[index].course_id) {
  //     history.push(`/module/${moduleID}/journey`)
  //   }
  // }

  function onVideoEnded() {
    setVideoDone(true)
  }

  function onPodcastEnded() {
    setPodcastDone(true)
  }

  function getCurrentUserProgress() {
    const userProgress = state.userProgress.find(o => o.journey_id === course.journey_id)
    const courseId = parseInt(props.match.params.id)

    if (!userProgress) {
      setActiveCourseStep(0)
    } else {
      if (!menuCourseSteOverwrite) {
        if (userProgress.last_step >= 1 && courseId === userProgress.last_course) {
          setVideoDone(true)
        }
        if (userProgress.last_step >= 2 && courseId === userProgress.last_course) {
          setPodcastDone(true)
        }
        if (userProgress.last_step >= 3 && courseId === userProgress.last_course) {
          setQuizPassed(true)
        }
        if (courseId < userProgress.last_course) {
          setActiveCourseStep(4)
          setVideoDone(true)
          setPodcastDone(true)
          setQuizPassed(true)
        } else if (courseId > userProgress.last_course && userProgress.last_step === 4) {
          setActiveCourseStep(0)
        } else {

          setActiveCourseStep(userProgress.last_step)
        }
      }
    }
    if (userProgress) {
      setCurrentUserProgress(userProgress)
    }
  }

  function onNextStepCourse() {
    const activeStep = activeCourseStep
    sendUserProgress()
    setActiveCourseStep(activeStep + 1)
    setActiveTranscriptStep(0)
  }

  function onShowQuizAnswer() {
    setShowCorrectAnswerModal(true)
  }

  function onPreviousStepCourse() {
    const activeStep = activeCourseStep
    setMenuCourseSteOverwrite(true)
    setActiveCourseStep(activeStep - 1)
    setActiveTranscriptStep(0)
  }

  function sendUserProgress() {
    const payload = {
      last_course: props.match.params.id,
      last_step: activeCourseStep + 1,
    }
    var config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + state.token
      }
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}api/user_progress/${course.journey_id}/next_course_step`, payload, config)
      .then(function (res) {
        if (res.status === 200) {
          dispatch({
            type: "USER_PROGRESS",
            payload: res.data.user_progress
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // let history = useHistory();
  const totalSelfReflection = selfReflection.length

  const fetchData = () => {
    var config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + state.token
      }
    }

    axios.get(`${process.env.REACT_APP_BASE_URL}api/v2/master/id/course/` + props.match.params.id + `/quiz`, config)
      .then(res => {
        setIsLoaded(true);
        setQuiz(res.data);
        getQuizStatus(res.data)
      })
      .catch(e => {
        setIsLoaded(true);
        console.log(e)
      })


    // axios.get(`${process.env.REACT_APP_BASE_URL}api/master/user-progress`, config)
    //   .then(res => {
    //     setSubscriptionStatus(res.data.subscription_status);
    //   })
    //   .catch(e => {
    //     console.log(e)
    //   })

    axios.get(`${process.env.REACT_APP_BASE_URL}api/id/course/${props.match.params.id}/user_self_reflection_answer`, config)
      .then(res => {
        if (res.data.length > 3) {
          let answer
          answer = res.data.map((sra) => sra.answer)
          setSelfReflectionDone(true)
          setSelfReflectionAnswer(answer)
        }
      })
      .catch(e => {
        console.log(e)
      })

    axios.get(`${process.env.REACT_APP_BASE_URL}api/v2/master/id/course/` + props.match.params.id, config)
      .then(res => {
        if (res.data.course == null) {
          setCourse({});
          // setModalSubscription(true);
        } else {
          setCourse(res.data.course);
        }
        setIsLoaded(true)
        // setMicrocourseId(props.match.params.id);
        setSpeakers(res.data.course.course_podcast_speakers)
        setSelfReflection(res.data.self_reflection)
        setPodcastTranscripts(sortTranscripts(res.data.course.podcast_transcripts))
        setVideoTranscripts(sortTranscripts(res.data.course.video_transcripts))
        setVideoURL(res.data.course.video_url)
        setPodcastURL(res.data.course.podcast_url)
        setPodcastImage(res.data.course.podcast_image)
        setObjetive(res.data.course.objective)
        setResources(res.data.course.learning_resources)

        axios.get(`${process.env.REACT_APP_BASE_URL}api/user_progress/${res.data.course.journey_id}/start_journey`, config)
          .then(res => {
            dispatch({
              type: "USER_PROGRESS",
              payload: res.data.user_progress
            })
          })
          .catch(e => {
            console.log(e)
          })

        axios
          .get(`${process.env.REACT_APP_BASE_URL}api/v2/master/id/journey/${res.data.course.journey_id}`, config)
          .then((res) => {
            setModuleID(res.data.module_id)
            setAllCourses(res.data.courses)
            // setFirstCoursePerJourney(res.data.courses[0].course_id);
            setCourseName(res.data.journey_name);
            
            // Get last course_id in journey to compare current course id in self reflection
            // If the two are same, page redirect to journey homepage, else redirect to next course
            const lastCourse = res.data.courses.pop();
            setLastCourseID(lastCourse?.course_id)

            const courseIndex = res?.data?.courses?.findIndex(obj => obj.course_id ===  parseInt(props.match.params.id))
            setCourseIndex(courseIndex + 1);
            setIsLoaded(true)
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch(e => {
        console.log(e)
      })
  }

  const timeout = () => {
    setTimeout(() => {
      console.log("Token telah berakhir")
      dispatch({
        type: "LOGOUT"
      })
    }, state.tokenExpires)
  }


  useEffect(() => {
    fetchData()
    getCurrentUserProgress()
    // eslint-disable-next-line
    timeout()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (state.userProgress.length > 0) {
      getCurrentUserProgress()
    }
    if (allCourses.length > 0) {
      // prevent user from being kicked back to module page, so validation below is commented
      // validationCourse()
    }
    // if (microcourseId === firstCoursePerJourney) {
    //   setModalSubscription(false);
    // }
  })



  if (!state.isAuthenticated) {
    return <Redirect to="/" />
  }

  function getQuizStatus(quiz) {
    var config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + state.token
      }
    }
    axios.get(`${process.env.REACT_APP_BASE_URL}api/v2/id/course/${props.match.params.id}/quiz_status`, config)
      .then(response => {
        if (!response.data.submitted) {

          setActiveQuizStep(0)
          setIsQuizAnswerSubmited(false)
        } else {
          setIsQuizAnswerSubmited(true)
          setQuizScore(response.data.score)
          setActiveQuizStep(quiz.length + 1)
          setQuizPassed(response.data.passed)
          setQuizStatus(response.data)
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  function selectQuizAnswer(quizId, answerId) {
    let answers = quizAnswerSelected;
    let result = answers.find((o, i) => {
      if (o.quiz_id === quizId) {
        answers[i] = { quiz_id: quizId, quiz_answer_id: answerId };
        return true; // stop searching
      }
      return false;
    });
    if (!result) {
      answers.push({
        quiz_id: quizId,
        quiz_answer_id: answerId,
      });
    }
    setQuizAnswerSelected(answers);
    forceUpdate();
  }

  function nextQuizStep() {
    const quizStep = activeQuizStep + 1;
    setActiveQuizStep(quizStep);
  }
  function previousQuizStep() {
    const quizStep = activeQuizStep - 1;
    setActiveQuizStep(quizStep);
  }

  function submitQuiz() {
    var config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + state.token
      }
    }
    if (quiz.length !== quizAnswerSelected.length) {
      return null
    }
    let payload = {}
    payload["course_id"] = props.match.params.id
    payload["user_quiz_answers"] = quizAnswerSelected

    axios.post(`${process.env.REACT_APP_BASE_URL}api/quiz`, payload, config)
      .then(function (res) {
        getQuizStatus(quiz)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function retakeQuiz() {
    var config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + state.token
      }
    }

    axios.get(`${process.env.REACT_APP_BASE_URL}api/v2/course/${props.match.params.id}/retake_quiz`, config)
      .then(function (res) {
        setActiveQuizStep(0)
        setQuizAnswerSelected([])
        getQuizStatus(quiz)
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function TranscriptMenuList({ title, onClick, active, keyID }) {
    const defaultClass = `cursor-pointer`
    let className = defaultClass

    if (active) className = `${defaultClass} menu-item-active`
    return (
      <a href={`#${keyID}`}><li onClick={onClick} className={className}>{title}</li></a>
    )
  }

  function changeTranscriptStep(order) {
    if (order === "objective") {
      setShowObjective(true)
      setShowResources(false)
      return
    }
    if (order === "resources") {
      setShowResources(true)
      setShowObjective(false)
      return
    }
    if (order === "transcript") {
      setShowObjective(false)
      setShowResources(false)
      return
    }
    setShowObjective(false)
    setShowResources(false)
    setActiveTranscriptStep(order - 1)
  }

  const handleCloseCorrectAnswerModal = () => {
    setShowCorrectAnswerModal(false);
  }

  const handleRetakeQuiz = () => {
    setShowCorrectAnswerModal(false);
    retakeQuiz();
  }

  return (
    <>
      <JourneyNavigationBar />
      <div className='content-wrapper microcourse'>
        <div className='microcourse__content-wrapper'>
          <Row>
            <Col md='12'>
            </Col>
            <Col md='12' style={{ marginBottom: 50, marginTop: 50 }}>
              <Link to={`/module/${moduleID}/journey`}><BackButton /></Link>
            </Col>
            <Col md='12' lg={4}>
              <MicrocourseProgress
                courseName={courseName}
                courseIndex={courseIndex}
                goToSpecificStepCourse={goToSpecificStepCourse}
                lastStep={currentUserProgress.last_step || 0}
                courseStep={activeCourseStep}
                courseId={props.match.params.id}
                lastCourse={currentUserProgress.last_course || parseInt(props.match.params.id)}
                isLoaded={isLoaded}
              />

            </Col>
            <Col md='12' lg={8}>
              <div className='microcourse-content-wrapper'>
                <div className='content-title'>
                  <h1>{t('course.welcome')}</h1>
                  <h1>Microcourse I - {course && course.course_name}</h1>
                </div>
                {activeCourseStep === 0 &&
                  <VideoPage
                    videoURL={videoURL}
                    onVideoEnded={onVideoEnded}
                  />
                }
                {activeCourseStep === 1 &&
                  <>
                    <PodcastPage
                      podcastURL={podcastURL}
                      podcastImage={podcastImage}
                      speakers={speakers}
                      onPodcastEnded={onPodcastEnded}
                    />
                  </>
                }
                {activeCourseStep === 2 &&
                  <>
                    <QuizPage
                      selectQuizAnswer={selectQuizAnswer}
                      quiz={quiz}
                      activeCourseStep={activeCourseStep}
                      quizAnswerSelected={quizAnswerSelected}
                      activeQuizStep={activeQuizStep}
                      quizScore={quizScore}
                      nextQuizStep={nextQuizStep}
                      isQuizAnswerSubmited={isQuizAnswerSubmited}
                      quizPassed={quizPassed}
                      submitQuiz={submitQuiz}
                      retakeQuiz={retakeQuiz}
                      onShowQuizAnswer={onShowQuizAnswer}
                      onNextStepCourse={onNextStepCourse}
                      previousQuizStep={previousQuizStep}
                    />
                    <CorrectAnswerModal
                      showCorrectAnswerModal={showCorrectAnswerModal}
                      handleCloseCorrectAnswerModal={handleCloseCorrectAnswerModal}
                      handleRetakeQuiz={handleRetakeQuiz}
                      quizStatus={quizStatus}
                      isLoaded={isLoaded}
                    />
                  </>
                }

                {
                  activeQuizStep >= 3 &&
                  <>
                    <SelfReflectionPage
                      selfReflection={selfReflection}
                      activeCourseStep={activeCourseStep}
                      activeSelfReflectionStep={activeSelfReflectionStep}
                      totalSelfReflection={totalSelfReflection}
                      courseName={course.course_name}
                      courseID={course?.course_id}
                      lastCourseID={lastCourseID}
                      selfReflectionDone={selfReflectionDone}
                      selfReflectionAnswer={selfReflectionAnswer}
                      onChangeSelfReflection={onChangeSelfReflection}
                      previousSelfReflectionStep={previousSelfReflectionStep}
                      submitSelfReflectionAnswer={submitSelfReflectionAnswer}
                      nextSelfReflectionStep={nextSelfReflectionStep}
                      errorValidation={selfReflectionError}
                      moduleID={moduleID}
                    />
                  </>
                }
              </div>

            </Col>
          </Row>
          {activeCourseStep >= 0 && activeCourseStep < 1 ?
            <Row>
              <Col md='12'>
                <div className='divider'></div>
              </Col>
              <Col md='3'>
                <div className={`menu-item ${objectiveActiveStyle}`} onClick={() => changeTranscriptStep("objective")}>{t('course.video.objective')}</div>
                <div className='menu-item-group'>
                  <div className='menu-item' onClick={() => changeTranscriptStep("transcript")}>{t('course.transcript')}</div>
                  <ul>
                    {videoTranscripts.map((videoTrascript) => (
                      <TranscriptMenuList title={videoTrascript.name} key={videoTrascript.key} keyID={videoTrascript.key} onClick={() => changeTranscriptStep(videoTrascript.order)} active={activeranscriptStep === videoTrascript.order - 1 && (!showObjective && !showResources)} />
                    ))}
                    {/* <li className='menu-item-active'>Intro</li>
                  <li>Membaca Terpandu dan Tujuannya</li>
                  <li>Strategi Membaca 5W 1H</li>
                  <li>Peran Guru</li>
                  <li>Apa yang Anda akan Pelajari</li> */}
                  </ul>
                </div>
                <div className={`menu-item ${resourceseActiveStyle}`} onClick={() => changeTranscriptStep("resources")}>{t('course.resource')}</div>

              </Col>
              <Col md='8'>
                <div className='content-detail-wrapper'>

                  {showObjective &&
                    <div dangerouslySetInnerHTML={{ __html: objective }} />
                  }

                  {showResources &&
                    <ul>
                      {resources.map((r) => <li><a href={r.resource_url}>{r.resource_url}</a></li>)}
                    </ul>
                  }

                  {videoTranscripts.length > 0 && (!showObjective && !showResources) ?
                    (
                      <>
                        {/* <div dangerouslySetInnerHTML={{ __html: videoTranscripts[activeranscriptStep].value }} />  */}
                        {videoTranscripts.map((vt) => (
                          <div id={vt.key} dangerouslySetInnerHTML={{ __html: vt.value }} />
                        ))}
                      </>
                    ) : null
                  }


                </div>
                <div className='button-group'>
                  {/* <Button className='black-button' onClick={onNextStepCourse} disabled={!videoDone}>Next <img src={ArrowRight} alt='arrow'  /></Button> */}
                  <Button disabled={!videoDone} className='black-button' onClick={onNextStepCourse} >{t('next')}<img src={ArrowRight} alt='arrow' /></Button>
                </div>
              </Col>
            </Row> : null
          }
          {activeCourseStep >= 1 && activeCourseStep < 2 ?
            <Row>
              <Col md='12'>
                <div className='divider'></div>
              </Col>
              <Col md='3'>
                <div className={`menu-item ${objectiveActiveStyle}`} onClick={() => changeTranscriptStep("objective")}>{t('course.video.objective')}</div>
                <div className='menu-item-group'>
                  <div className='menu-item'>{t('course.transcript')}</div>
                  <ul>
                    {podcastTranscripts.map((podcastTranscript) => (
                      <TranscriptMenuList title={podcastTranscript.name} key={podcastTranscript.key} keyID={podcastTranscript.key} onClick={() => changeTranscriptStep(podcastTranscript.order)} active={activeranscriptStep === podcastTranscript.order - 1 && !showObjective} />
                    ))}

                  </ul>
                </div>

              </Col>
              <Col md='8'>
                <div className='content-detail-wrapper'>

                  {podcastTranscripts.length > 0 && !showObjective ?
                    <>
                      {podcastTranscripts.map((pt) => (
                        <div id={pt.key} dangerouslySetInnerHTML={{ __html: pt.value }} />
                      ))}
                    </>
                    : null
                  }

                  {showObjective &&
                    <div dangerouslySetInnerHTML={{ __html: objective }} />
                  }

                </div>
                <div className='button-group'>
                  <Button className='white-button' onClick={onPreviousStepCourse}><img src={ArrowLeft} alt='arrow' />{t('back')}</Button>
                  <Button disabled={!podcastDone} className='black-button' onClick={onNextStepCourse}>{t('next')}<img src={ArrowRight} alt='arrow' /></Button>
                </div>
              </Col>
            </Row> : null
          }
          <div className='button-group mt-4'>
            {activeCourseStep > 1 &&
              <Button className='white-button' onClick={onPreviousStepCourse}><img src={ArrowLeft} alt='arrow' />{t('back')}</Button>
            }
          </div>
          {/* <Row>
            <Col md='12'>
              <div className='divider'></div>
            </Col>
            <Col md='3'>
              <div className='menu-item'>About This Journey</div>
              <div className='menu-item'>Pre-Test</div>
              <div className='menu-item'>Course Objectives</div>
              <div className='menu-item-group'>
                <div className='menu-item'>Video Transcript</div>
                <ul>
                  <li className='menu-item-active'>Intro</li>
                  <li>Membaca Terpandu dan Tujuannya</li>
                  <li>Strategi Membaca 5W 1H</li>
                  <li>Peran Guru</li>
                  <li>Apa yang Anda akan Pelajari</li>
                </ul>
              </div>
              <div className='menu-item'>Learning Resources</div>

              <div className='menu-item-group'>
                <div className='menu-item'>Video Transcript</div>
                <ul>
                  <li className='menu-item-active'>Intro</li>
                  <li>Content</li>
                  <li>Closing</li>
                </ul>
              </div>
            </Col>
            <Col md='8'>
              <div className='content-detail-wrapper'>
                <h3>Pre-Test</h3>
                <div id='pre-test'>
                  <Form>
                    <Form.Group controlId="ControlTextarea1">
                      <Form.Label>1. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium?</Form.Label>
                      <Form.Control as="textarea" rows={10} />
                    </Form.Group>
                    <Form.Group controlId="ControlTextarea2">
                      <Form.Label>2. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium?</Form.Label>
                      <Form.Control as="textarea" rows={10} />
                    </Form.Group>
                  </Form>
                </div>
                <h3>Intro</h3>
                <div id='pre-test'>
                  <p>Aubry:</p>
                  <p>“Halo Generasi Mengajar! Dalam perjalanan ini kalian akan ditemani oleh saya di setiap episode membahas setiap kursus lebih dalam lagi. Saya Aubry Beer sebagai host dan Mba Adrien Dyamantha sebagai salah satu pendiri dari Sekoline, yaitu platform belajar online untuk anak SD. Semoga terinspirasi ya!”</p>

                  <b>Content</b>
                  <p>Aubry:</p>
                  <p>“Ya, di episode 1 kali ini, kita akan membahas mengenai membaca terpandu. Hai mba Adrin, langsung aja ya tanya-tanya ya. Tadi kalau di video sudah sempet nonton nih.
                    Benar, kalau kita relate sama jaman aku sekolah dulu, ada teman aku dulu juara kelas, tapi kalo pas aku nanya, sebenernya paham atau engga, ternyata emang ngga paham, Cuma jago aja ngafalnya makanya bisa jadi juara kelas. Berarti memang sepenting itu ya membaca dan juga memahami isi bacaannya</p>
                </div>
                <div id='about-journey'>
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?</p>
                  <p><b>In this journey, you will learn:</b></p>
                  <ul>
                    <li>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</li>
                    <li>Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo</li>
                    <li>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni </li>
                    <li>Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo</li>
                    <li>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni </li>
                  </ul>
                </div>
              </div>
              <div className='button-group'>
                <Button className='white-button' style={{ marginRight: 23 }}><img src={ArrowLeft} alt='arrow' />Back</Button>
                <Button className='black-button'>Next <img src={ArrowRight} alt='arrow' /></Button>
              </div>
            </Col>
          </Row> */}
        </div>
      </div>
      <img className='tetris-footer' src={TetrisFooter} alt='tetris' />
      {/* {
        modalSubscription &&
        <Modal
          show={showSubscribeModal}
          onHide={handleCloseSubscribeModal}
          backdrop="static"
          keyboard={false}
          className='subscribe-tetris-modal'
        >
          <Modal.Body>
            <img src={SubscribeModalTetris} alt='tetris' />
            <h3>{t('subscribe.modal-title')}</h3>
            <p>{t('subscribe.modal-description')}</p>
            <Link to='/subscription' className='pink-button'>Activate My Subscription</Link>
          </Modal.Body>
        </Modal>
      } */}
    </>
  );
}

export default IndexMicrocourse;
