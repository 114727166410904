import React, { useContext, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { AuthContext } from '../../App';
import axios from 'axios';
import { Button, Form, Alert, InputGroup } from 'react-bootstrap';
import './Auth.scss';
import { useTranslation } from 'react-i18next';
import AuthSidebar from '../../components/AuthSidebar/AuthSidebar';
import FooterTetris from '../../assets/images/auth/footer-tetris.svg'


const qs = require('querystring');
export default function ResetPassword(props) {
    const { t } = useTranslation()
    const { state, dispatch } = useContext(AuthContext)
    const initialState = {
        email: "",
        password: "",
        confirm_password: "",
        isSubmitting: false,
        errorMessage: null,
    }
    const [data, setData] = useState(initialState);
    const handleInputChange = event => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }
    const handleFormSubmit = event => {
        event.preventDefault()
        setData({
            ...data,
            isSubmitting: true,
            errorMessage: null
        })
        if (data.password !== data.confirm_password) {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: "Pastikan password dan konfirmasi password sama"
            })
        } else {
            const requestBody = {
                email: props.match.params.token,
                password: data.password
            }
            const config = {
                header: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            axios.post(`${process.env.REACT_APP_BASE_URL}api/reset-password`, qs.stringify(requestBody), config)
                .then(response => {
                    dispatch({
                        payload: response.data
                    })
                    props.history.push("/dashboard");
                })
                .catch(error => {
                    setData({
                        ...data,
                        isSubmitting: false,
                        errorMessage: "Password baru dan konfirmasi password tidak boleh kosong"
                    })
                });
        }

    }

    if (state.isAuthenticated) {
        return <Redirect to="/dashboard" />
    }

    return (
        <div className='auth'>
            <div className='background-layer'>
                <AuthSidebar />
                <div className='auth-form'>
                    <div className='auth-form-title'>{t('slogan.title')}</div>
                    <div className='auth-form-subtitle'>
                        <span>{t('create.new.password')}</span>
                    </div>
                    <Form onSubmit={handleFormSubmit} className='form-wrapper'>
                        <Form.Control name="email" type="hidden" value={data.email} />
                        <Form.Label>{t('new.password')}</Form.Label>
                        <InputGroup className='mb-3'>
                            <Form.Control name="password" type="password" value={data.password} onChange={handleInputChange} />
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.1428 8.92868H15.625V3.92868C15.625 2.35055 14.3459 1.07153 12.7678 1.07153H7.23211C5.65398 1.07153 4.37497 2.35055 4.37497 3.92868V8.92868H2.85711C2.46202 8.92868 2.14282 9.24787 2.14282 9.64296V18.2144C2.14282 18.6095 2.46202 18.9287 2.85711 18.9287H17.1428C17.5379 18.9287 17.8571 18.6095 17.8571 18.2144V9.64296C17.8571 9.24787 17.5379 8.92868 17.1428 8.92868ZM5.98211 3.92868C5.98211 3.23894 6.54238 2.67868 7.23211 2.67868H12.7678C13.4576 2.67868 14.0178 3.23894 14.0178 3.92868V8.92868H5.98211V3.92868ZM16.25 17.3215H3.74997V10.5358H16.25V17.3215ZM9.37497 14.2189V15.4019C9.37497 15.5001 9.45532 15.5805 9.55354 15.5805H10.4464C10.5446 15.5805 10.625 15.5001 10.625 15.4019V14.2189C10.8092 14.0866 10.9468 13.8992 11.0178 13.6838C11.0888 13.4684 11.0896 13.236 11.0201 13.02C10.9506 12.8041 10.8144 12.6158 10.6311 12.4822C10.4478 12.3487 10.2268 12.2767 9.99997 12.2767C9.77314 12.2767 9.55216 12.3487 9.36884 12.4822C9.18552 12.6158 9.04932 12.8041 8.97983 13.02C8.91035 13.236 8.91117 13.4684 8.98217 13.6838C9.05318 13.8992 9.19071 14.0866 9.37497 14.2189Z" fill="black" />
                            </svg>
                        </InputGroup>
                        <Form.Label>{t('confirm.new.password')}</Form.Label>
                        <InputGroup className='mb-3'>
                            <Form.Control name="confirm_password" type="password" value={data.confirm_password} onChange={handleInputChange} />
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.1428 8.92868H15.625V3.92868C15.625 2.35055 14.3459 1.07153 12.7678 1.07153H7.23211C5.65398 1.07153 4.37497 2.35055 4.37497 3.92868V8.92868H2.85711C2.46202 8.92868 2.14282 9.24787 2.14282 9.64296V18.2144C2.14282 18.6095 2.46202 18.9287 2.85711 18.9287H17.1428C17.5379 18.9287 17.8571 18.6095 17.8571 18.2144V9.64296C17.8571 9.24787 17.5379 8.92868 17.1428 8.92868ZM5.98211 3.92868C5.98211 3.23894 6.54238 2.67868 7.23211 2.67868H12.7678C13.4576 2.67868 14.0178 3.23894 14.0178 3.92868V8.92868H5.98211V3.92868ZM16.25 17.3215H3.74997V10.5358H16.25V17.3215ZM9.37497 14.2189V15.4019C9.37497 15.5001 9.45532 15.5805 9.55354 15.5805H10.4464C10.5446 15.5805 10.625 15.5001 10.625 15.4019V14.2189C10.8092 14.0866 10.9468 13.8992 11.0178 13.6838C11.0888 13.4684 11.0896 13.236 11.0201 13.02C10.9506 12.8041 10.8144 12.6158 10.6311 12.4822C10.4478 12.3487 10.2268 12.2767 9.99997 12.2767C9.77314 12.2767 9.55216 12.3487 9.36884 12.4822C9.18552 12.6158 9.04932 12.8041 8.97983 13.02C8.91035 13.236 8.91117 13.4684 8.98217 13.6838C9.05318 13.8992 9.19071 14.0866 9.37497 14.2189Z" fill="black" />
                            </svg>
                        </InputGroup>
                        {data.errorMessage && (
                            <Alert variant="danger">
                                {data.errorMessage}
                            </Alert>
                        )}
                        <Button className='w-100 auth-button' type="submit" disabled={data.isSubmitting}>
                            {data.isSubmitting ? (
                                "Loading.."
                            ) : (
                                "Change Password"
                            )}
                        </Button>
                        <div className='auth-divider' />
                        <div className='cta-label'>
                            <span>{t('remember.password')}</span>
                            <Link to='/'>{t('sign.in')}</Link>
                        </div>
                    </Form>
                    <img className='auth-footer-tetris' src={FooterTetris} alt='tetris' />
                </div>
            </div>
        </div>
    )
}