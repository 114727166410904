import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { AuthContext } from '../../App';
import { Link, Redirect } from 'react-router-dom'
import { Row, Col, Card, Spinner } from 'react-bootstrap';
import NavigationBar from '../../components/NavigationBar';
import { useTranslation } from 'react-i18next';
import './Module.scss';

import TetrisDecoration1 from '../../assets/images/module/tetris-decoration-1.png';
import TetrisDecoration2 from '../../assets/images/module/tetris-decoration-2.png';

export default function ListModule() {
  const [module, setModule] = useState([]);
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(AuthContext);
  const { t } = useTranslation();

  const fetchData = () => {
    var config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + state.token
      }
    }

    axios.get(`${process.env.REACT_APP_BASE_URL}api/v2/master/id/module`, config)
      .then(res => {
        setModule(res.data);
        setLoading(true);
      })
      .catch(e => {
        console.log(e)
      })
  }

  const timeout = () => {
    setTimeout(() => {
      console.log("Token telah berakhir")
      dispatch({
        type: "LOGOUT"
      })
    }, state.tokenExpires)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
    timeout()
    // eslint-disable-next-line
  }, [])

  if (!state.isAuthenticated) {
    return <Redirect to="/" />
  }

  return (
    <>
      <NavigationBar />
      <div className='content-wrapper module'>
        <div className='module__content-wrapper'>
          <Row>
            <Col>
              <div className='row-center'>
                <h1>{t('module.header')}</h1>
                <div className='module__content'>
                  <Row className='wrap-content'>
                    {loading ?
                      <>
                        {module.map(module =>
                          <Link key={module.id} to={`/module/${module.id}/journey`}>
                            <Card className='module-card'>
                              <div className='module-card__overlay' />
                              <div className='module-card__image-wrapper'>
                                <Card.Img variant="top" src={module.img_url} />
                              </div>
                              <div className='module-title'>
                                <h5>{module.module_name}</h5>
                              </div>
                            </Card>
                          </Link>
                        )}
                      </>
                      :
                      <div className='w-100 d-flex justify-content-center mt-3'>
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    }
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='tetris-decoration'>
          <img alt='tetris' src={TetrisDecoration1} />
          <img alt='tetris' src={TetrisDecoration2} />
        </div>
      </div>
    </>
  )
}
