import React from 'react';
import { Card } from 'react-bootstrap';
import './NavigationBanner.scss';


export default function NavigationBanner(props) {
  let title
  if (props.isActive) {
    title = <h5>{props.title}</h5>
  } else {
    title = <h5 className="inactive">{props.title}</h5>
  }
  return (
    <a href={props.url}>
      <Card className="nav-card">
        <Card.Img src={props.img} alt={title} />
        <Card.ImgOverlay>
          <div className="nav-title">
            {title}
          </div>
        </Card.ImgOverlay>
      </Card>
    </a>
  )
}