import React from "react";
import { Previous, Next, Submit } from "../StepperButtons/Index";

class Step extends React.Component {
  render() {
    const {
      isActive,
      displayPrevious,
      displayNext,
      displaySubmit,
      component,
      children,
    } = this.props;
    if (isActive === false) return null;

    return (
      <React.Fragment>
        {component ? React.createElement(component) : children}
        <div className="d-flex flex-column flex-md-row gap-4 mt-2 quiz-step-button">
          <Previous
            className="mt-3 prev-btn"
            isActive={displayPrevious}
            goToPreviousStep={() => this.props.goToPreviousStep()}
          />
            <Next
              className="mt-3 next-btn"
              isActive={displayNext}
              goToNextStep={() => this.props.goToNextStep()}
            />
        </div>
        <Submit isActive={displaySubmit} />
      </React.Fragment>
    );
  }
}

export { Step };
