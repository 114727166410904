import React, { useState, useContext } from 'react';
import {
  Nav,
  Navbar,
  NavDropdown,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { AuthContext } from '../App';
import './NavigationBar.scss';
import { useTranslation } from 'react-i18next';
import Logo from '../assets/images/navigationbar/gened-logo.svg';
import UserPlaceholder from '../assets/images/user-placeholder.jpeg';
import idFlag from '../assets/images/id-flag.svg';
import usaFlag from '../assets/images/usa-flag.svg';

function NavigationBar() {
  const [navbar, setNavbar] = useState(false);
  const [locale, setLocale] = useState('id');
  const { i18n } = useTranslation();
  const { dispatch } = useContext(AuthContext);

  const addNavbarShadow = () => {
    if (window.scrollY > 0) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  }

  window.addEventListener('scroll', addNavbarShadow)

  const handleSelect = (e) => {
    setLocale(e);
    i18n.changeLanguage(e);
  }

  let navFlag;
  if (locale === "id") {
    navFlag = <img src={idFlag} alt='id flag' />
  } else {
    navFlag = <img src={usaFlag} alt='en flag' />
  }

  return (
    <Navbar
      className={navbar ? 'custom-navbar custom-navbar-onscroll' : 'custom-navbar'}
      collapseOnSelect
      sticky="top"
      expand="lg"
      variant="light">

      <a href="https://gened.info">
        <img className='custom-navbar__logo' alt='logo' src={Logo} />
      </a>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href='https://gened.info'>Home</Nav.Link>
          <Nav.Link href='/dashboard'>Dashboard</Nav.Link>
          <NavDropdown title="Program" id="collasible-nav-dropdown">
            <NavDropdown.Item href="/module">Innovation Learning</NavDropdown.Item>
            <NavDropdown.Item href="https://library.gened.info">Innovation Library</NavDropdown.Item>
            <NavDropdown.Item disabled style={{opacity: 0.4}} href="https://community.gened.info">Innovation Community</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="https://gened.info/maintenance">Leaderboard</Nav.Link>
          <Nav.Link href="/subscription">Subscription</Nav.Link>
        </Nav>
        <Nav>
          <DropdownButton
            alignRight
            title={navFlag}
            className="language-picker"
            onSelect={handleSelect}
          >
            <Dropdown.Item eventKey="id"><img src={idFlag} alt='id flag' /> Indonesian</Dropdown.Item>
            <Dropdown.Item eventKey="en"><img src={usaFlag} alt='en flag' /> English</Dropdown.Item>
          </DropdownButton>
          <NavDropdown className='profile-dropdown'
            title={
              <div className="user-profile">
                <img className="user-img"
                  src={UserPlaceholder}
                  alt="user pic"
                />
              </div>
            }
            id="basic-nav-dropdown">

            <NavDropdown.Item eventKey={1.1} href="#profile">Profile</NavDropdown.Item>
            <NavDropdown.Item eventKey={1.1} onClick={() => dispatch({ type: "LOGOUT" })}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
