import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// import LeftArrow from '../../assets/images/left-arrow.svg';

import './Button.scss';

function BackButton() {
    const { t } = useTranslation();

    return (
        <Button className='back-button rounded-0'>
            <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                    <path d="M1.44735 16.2056L28.7935 16.2056C29.4599 16.2056 30 15.6651 30 14.9991C30 14.3331 29.4599 13.7926 28.7935 13.7926L1.44735 13.7926C0.78101 13.7926 0.240885 14.3327 0.240885 14.9991C0.240885 15.6655 0.780951 16.2056 1.44735 16.2056Z" fill="white" />
                    <path d="M0.35312 15.8538L12.1759 27.6771C12.6473 28.148 13.4105 28.148 13.8819 27.6771C14.3532 27.2057 14.3532 26.4417 13.8819 25.9707L2.912 15.0005L13.8819 4.0302C14.3532 3.55927 14.3532 2.7952 13.8819 2.32428C13.6462 2.08861 13.3377 1.97078 13.0289 1.97078C12.72 1.97078 12.4116 2.08861 12.1759 2.32428L0.35312 14.1475C-0.118214 14.6188 -0.118214 15.3829 0.35312 15.8538Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="30" height="30" fill="white" transform="translate(30 30) rotate(-180)" />
                    </clipPath>
                </defs>
            </svg>

            {/* <img src={ LeftArrow } alt='arrow-left icon' />  */}
            {t('back')}
        </Button>
    );
}

export default BackButton;
