import React from 'react';

import RightArrow from '../../assets/images/arrow-right-white.svg';

import './Button.scss';

function NextBlueButton({onClick}) {
    return (
       <div onClick={() => onClick()} className='quiz-button'>
           <img src={ RightArrow } alt='arrow-right icon' />
        </div>
    );
}

export default NextBlueButton;
