import React from 'react';

import LeftArrow from '../../assets/images/arrow-left-white.svg';

import './Button.scss';

function BackBlueButton({onClick}) {
    return (
       <div onClick={() => onClick()} className='quiz-button'>
           <img src={ LeftArrow } alt='arrow-left icon' />
        </div>
    );
}

export default BackBlueButton;
