import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/logo-white.svg'
import InnovationCommunity from '../../assets/images/auth/innovation-community.svg'
import InnovationLibrary from '../../assets/images/auth/innovation-library.svg'
import InnovationLearning from '../../assets/images/auth/innovation-learning.svg'

function AuthSidebar() {
    const [locale, setLocale] = useState('en');
    const { t, i18n } = useTranslation();
    const settings = {
        autoplay: true,
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        swipeToSlide: true,
        vertical: true,
        verticalSwiping: true
    };

    const handleSelect = (e) => {
        setLocale(e);
        i18n.changeLanguage(e);
    } 
    
    let navFlag;
    if(locale === "id") {
      navFlag=<div className='locale-option'><span>ID</span><span className='locale-en'> / EN</span></div>
    }  else {
      navFlag=<div className='locale-option'><span className='locale-id'>ID / </span><span>EN</span></div>
    }
    
    return (
        <div className='auth-sidebar'>
            <DropdownButton
                alignRight
                title={navFlag}
                className="language-picker"
                onSelect={handleSelect}
            >
                <Dropdown.Item eventKey="en">English</Dropdown.Item>
                <Dropdown.Item eventKey="id">Indonesian</Dropdown.Item>
            </DropdownButton>
            <Link to='/' className='d-flex align-items-center'>
                <img src={Logo} alt='logo' />
                <span className='logotype'>GenEd</span>
            </Link>
            <p className='sidebar-title'>{t('slogan.title')}</p>
            <p className='sidebar-description'>{t('slogan.description')}</p>
            <Slider {...settings} className='custom-carousel'>
                <div>
                    <img src={InnovationCommunity} alt='innovation community' />
                    <p className='carousel-item-title'>{t('innovation.learning')}</p>
                    <p className='carousel-item-description'>{t('innovation.learning.desc')}</p>
                </div>
                <div>
                    <img src={InnovationLibrary} alt='innovation library' />
                    <p className='carousel-item-title'>{t('innovation.library')}</p>
                    <p className='carousel-item-description'>{t('innovation.library.desc')}</p>
                </div>
                <div>
                    <img src={InnovationLearning} alt='innovation learning' />
                    <p className='carousel-item-title'>{t('innovation.community')}</p>
                    <p className='carousel-item-description'>{t('innovation.community.desc')}</p>
                </div>
            </Slider>
            <div className='sidebar-content'>
                <div>
                    <img src={InnovationCommunity} alt='innovation community' />
                    <p className='carousel-item-title'>{t('innovation.learning')}</p>
                    <p className='carousel-item-description'>{t('innovation.learning.desc')}</p>
                </div>
                <div>
                    <img src={InnovationLibrary} alt='innovation library' />
                    <p className='carousel-item-title'>{t('innovation.library')}</p>
                    <p className='carousel-item-description'>{t('innovation.library.desc')}</p>
                </div>
                <div>
                    <img src={InnovationLearning} alt='innovation learning' />
                    <p className='carousel-item-title'>{t('innovation.community')}</p>
                    <p className='carousel-item-description'>{t('innovation.community.desc')}</p>
                </div>
            </div>
        </div>
    )
}

export default AuthSidebar;

