import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import style from './CorrectAnswerModal.module.css';

export default function CorrectAnswerModal({ showCorrectAnswerModal, quizStatus, handleCloseCorrectAnswerModal, handleRetakeQuiz, isLoaded }) {
    const { t } = useTranslation();
    return (
        <Modal show={showCorrectAnswerModal} onHide={handleCloseCorrectAnswerModal} size='lg' className={style['correct-answer-modal']}>
            <Modal.Header closeButton>
                <Modal.Title>{t('course.quiz.correct.answers')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isLoaded && <Spinner animation="border" />}
                {isLoaded && quizStatus?.quiz?.map((qs, i) => {
                    return (
                        <div key={i} className='mb-5'>
                            <h5 className='mb-3'>{qs.question}</h5>
                            {
                                qs.quiz_answers.map((qa, i) => {

                                    return (
                                        <div key={i} className={style['quiz-answer']}>
                                            {qa.correct === 1 ?
                                                <div className={style['quiz-correct-answer-item']}>
                                                    <div>
                                                        {qa.answer}
                                                    </div>
                                                </div>
                                                :
                                                <div className={style['quiz-answer-item']}>
                                                    <div>
                                                        {qa.answer}
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button
                  onClick={handleCloseCorrectAnswerModal}
                  className="white-button"
                >
                  {t('close')}
                </Button>
                <Button
                  onClick={handleRetakeQuiz}
                  className="blue-button"
                >
                  {t('course.quiz.retake')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
