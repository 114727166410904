import React, { useReducer, createContext } from 'react';
import {
    Route,
    BrowserRouter,
    Switch,
} from "react-router-dom";
import './i18n';
import Cookies from 'js-cookie';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ListModule from './pages/module/ListModule';
import Journey from './pages/journey/IndexJourney';
import Microcourse from './pages/microcourse/IndexMicrocourse';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import IndexSurvey from './pages/microcourse/IndexPrecourse';
import FinalMicrocourse from './pages/microcourse/FinalMicrocourse'
import ForgotPasswordSent from './pages/auth/ForgotPasswordSent';
import SubscriptionIndex from './pages/subscription/SubscriptionIndex';
import PaymentMethodIndex from './pages/payment-method/PaymentMethodIndex';
import PaymentStatus from './pages/payment-method/PaymentStatus';
import UnderConstruction from './pages/other/under-construction/UnderConstruction';
import Dashboard from './pages/dashboard/dashboard';
import { config } from './constants/constants';
var cookie_domain = config.url.COOKIE_DOMAIN


export const AuthContext = createContext()

const initialState = {
    isAuthenticated: Cookies.get('user') != null ? true : false,
    user: Cookies.get('user', { path: '/', domain: cookie_domain }),
    token: Cookies.get('token', { path: '/', domain: cookie_domain }) == null ? null : Cookies.get('token', { path: '/', domain: cookie_domain }),
    tokenExpires: Cookies.get('expires_in', { path: '/', domain: cookie_domain }) == null ? null : Cookies.get('expires_in', { path: '/', domain: cookie_domain }),
    userProgress: localStorage.getItem('userProgress') == null ? null : JSON.parse(localStorage.getItem('userProgress'))
}

const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            Cookies.set('user', JSON.stringify(action.payload.user), { path: '/', domain: cookie_domain })
            Cookies.set('token', action.payload.token.access_token, { path: '/', domain: cookie_domain })
            Cookies.set('expires_in', JSON.stringify(action.payload.token.expires_in), { path: '/', domain: cookie_domain })

            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                token: action.payload.token.access_token,
                tokenExpires: action.payload.token.expires_in,
                userProgress: action.payload.userProgress
            }

        case "FORGOT_PASSWORD":
            localStorage.setItem("email", JSON.stringify(action.payload))
            return {
                ...state,
                email: action.payload
            }

        case "USER_PROGRESS":
            localStorage.setItem("userProgress", JSON.stringify(action.payload))
            return {
                ...state,
                userProgress: action.payload
            }

        case "LOGOUT":
            localStorage.clear();
            Cookies.remove('accessToken', { path: '/', domain: cookie_domain });
            Cookies.remove('expires_in', { path: '/', domain: cookie_domain });
            Cookies.remove('user', { path: '/', domain: cookie_domain });
            return {
                ...state,
                isAuthenticated: false,
                user: null
            }

        default:
            return state;
    }
}

export default function App() {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <BrowserRouter>
            <Switch>
                <AuthContext.Provider value={{
                    state,
                    dispatch
                }}>
                    <Route exact path="/" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/reset-password-sent" component={ForgotPasswordSent} />
                    <Route path="/reset-password/:token" component={ResetPassword} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/module" component={ListModule} />
                    <Route path="/module/:id/journey" component={Journey} />
                    <Route path="/journey/:id/precourse" component={IndexSurvey} />
                    <Route path="/microcourse/:id" component={Microcourse} />
                    <Route path="/journey/:journeyID/microcourse/final" component={FinalMicrocourse} />
                    <Route path="/subscription" component={SubscriptionIndex} />
                    <Route path="/payment-method" component={PaymentMethodIndex} />
                    <Route path="/payment-status" component={PaymentStatus} />
                    <Route path="/under-construction" component={UnderConstruction} />
                </AuthContext.Provider>
            </Switch>
        </BrowserRouter>
    );
}