import { Button, ProgressBar } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { StepList, Step } from "../../components/Stepper/Index";
import TetrisPiece1 from "../../assets/images/microcourse/tetris-piece-1.svg";
import TetrisPiece2 from "../../assets/images/microcourse/tetris-piece-2.svg";
import TetrisPiece3 from "../../assets/images/microcourse/tetris-piece-3.svg";
import TetrisPiece4 from "../../assets/images/microcourse/tetris-piece-4.svg";
import TetrisPiece5 from "../../assets/images/microcourse/tetris-piece-5.svg";
import TetrisPiece6 from "../../assets/images/microcourse/tetris-piece-6.svg";

function QuizPage({
  quiz,
  quizAnswerSelected,
  activeQuizStep,
  quizScore,
  nextQuizStep,
  previousQuizStep,
  isQuizAnswerSubmited,
  quizPassed,
  submitQuiz,
  retakeQuiz,
  onShowQuizAnswer,
  onNextStepCourse,
  selectQuizAnswer
}) {

  const { t } = useTranslation();
  const totalQuiz = quiz.length;
  function isActiveAnswer(quizAnswerId, quizId) {
    const exist = quizAnswerSelected.find(
      (o) => o.quiz_answer_id === quizAnswerId && o.quiz_id === quizId
    );
    if (exist) {
      return true;
    }
    return false;
  }

  function QuizAnswer({ qa, onClick, active }) {
    const defaultClass = "quiz-answer-button";
    let className = `${defaultClass}`;

    if (active) {
      className = `${defaultClass} active`;
    }

    return (
      <Button onClick={onClick} className={className}>
        {qa.answer}
      </Button>
    );
  }

  function QuizClosing() {
    return (
      <div >

        {isQuizAnswerSubmited && (
          <>
            {quizScore > 74 ? (
              <>
                <h1 style={{ marginBottom: 28 }}>{t('course.quiz.good')}</h1>
                <h1 style={{ marginBottom: 28 }}>
                  {t('course.quiz.score')} <span className="total-score">{quizScore}</span>
                  /100
                </h1>
              </>
            ) : (
              <>
                <h1 style={{ marginBottom: 28 }}>{t('course.quiz.bad')} &#128517;</h1>
                <h1 style={{ marginBottom: 14 }}>
                  {t('course.quiz.score')} <span className="total-score">{quizScore}</span>
                  /100
                </h1>
                <p style={{ marginBottom: 28 }}>
                  {t('course.quiz.minimum')}
                </p>
              </>
            )}
          </>
        )}
        <div className="button-group">
          {!isQuizAnswerSubmited ? (
            <Button
              onClick={submitQuiz}
              className="blue-button"
              style={{ width: 235 }}
            >
              {t('submit')}
            </Button>
          ) : (
            <>
              <Button
                onClick={retakeQuiz}
                className="white-button"
                style={{ marginRight: 25 }}
              >
                {t('course.quiz.retake')}
              </Button>
              {quizPassed ?
                <Button
                  onClick={onNextStepCourse}
                  className="blue-button"
                  style={{ width: 235 }}
                >
                  {t('next')}
                </Button>
                :
                <Button
                  onClick={onShowQuizAnswer}
                  className="blue-button"
                  style={{ width: 235 }}
                >
                  {t('course.quiz.answers')}
                </Button>
              }
            </>
          )}
        </div>
      </div>
    );
  }

  function QuizOpening() {
    return (
      <div className="quiz-box quiz-box-gate">
        <h1 style={{ marginBottom: 28 }}>{t('course.quiz.ready')}</h1>
        <Button className="blue-button" onClick={nextQuizStep}>
          {t('course.quiz.start')}
        </Button>
        <div className="tetris-wrapper">
          <img
            className="tetris-decoration-1"
            src={TetrisPiece1}
            alt="tetris piece"
          />
          <img
            className="tetris-decoration-2"
            src={TetrisPiece2}
            alt="tetris piece"
          />
          <img
            className="tetris-decoration-3"
            src={TetrisPiece3}
            alt="tetris piece"
          />
          <img
            className="tetris-decoration-4"
            src={TetrisPiece4}
            alt="tetris piece"
          />
          <img
            className="tetris-decoration-5"
            src={TetrisPiece5}
            alt="tetris piece"
          />
          <img
            className="tetris-decoration-6"
            src={TetrisPiece6}
            alt="tetris piece"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="microcourse__quiz">
      <>
        <ProgressBar
          className="quiz-progressbar"
          now={(activeQuizStep / (totalQuiz + 1)) * 100}
        />
        <div className="quiz-box quiz-box-ongoing">
          {activeQuizStep > 0 && activeQuizStep <= totalQuiz ? (
            <div>
              {t('course.quiz.question')} {activeQuizStep}/{totalQuiz}{" "}
              {activeQuizStep <= totalQuiz}
            </div>
          ) : null}

          <StepList currentStep={activeQuizStep} goNextStep={nextQuizStep} goPreviousStep={previousQuizStep}>
            <Step
              key="quizopening"
              displayNext={false}
              displayPrevious={false}
              component={QuizOpening}
            />
            {quiz.map((q) => (
              <Step key={q.id}>
                <li>{q.question}</li>
                {q.quiz_answers.map((qa) => (
                  <QuizAnswer
                    key={qa.quiz_answer_id}
                    qa={qa}
                    onClick={() => {
                      selectQuizAnswer(qa.quiz_id, qa.quiz_answer_id);
                    }}
                    active={isActiveAnswer(qa.quiz_answer_id, qa.quiz_id)}
                  />
                ))}
              </Step>
            ))}
            <Step
              key="lastpage"
              displaySubmit={false}
              displayPrevious={false}
              component={QuizClosing}
            />
          </StepList>
          <div className="tetris-wrapper">
            <img
              className="tetris-decoration-1"
              src={TetrisPiece1}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-2"
              src={TetrisPiece2}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-3"
              src={TetrisPiece3}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-4"
              src={TetrisPiece4}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-5"
              src={TetrisPiece5}
              alt="tetris piece"
            />
            <img
              className="tetris-decoration-6"
              src={TetrisPiece6}
              alt="tetris piece"
            />
          </div>
        </div>
      </>
    </div>
  );
}
export default QuizPage;
